import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CachedDataElements } from '../../models/cached-data';
import { ConfigService } from '../../services/config.service';
import { DataFilter, DataService, PgFilterStatus } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { PgFileType } from '../../models/file.model';

@Component({
  selector: 'app-pg-resource-picker',
  templateUrl: './pg-resource-picker.component.html',
  styleUrls: ['./pg-resource-picker.component.scss']
})
export class PgResourcePickerComponent implements OnInit, OnChanges {

    @Input() resourceId:string = null;

    @Input() relatedResource:string = null;
    @Input() relatedElement:string = null;

    @Input() filterStatus:PgFilterStatus = null;

    @Input() selectionMode:'single'|'multi' = null;
    @Input() selectionField:string = null;
    @Input() selection:Array<string> = null;

    @Input() useCache:CachedDataElements = null;
    @Input() customContent:boolean = false;

    @Output() selectionChange = new EventEmitter<Array<string>>();

    resourceName:string = null;
    viewType:string = null;
    fileType:PgFileType = null;

    selectionData:Array<any> = null;

    appliedFilter:PgFilterStatus = null;

    hasTags = false;
    hasSystemTags = false;

    constructor(private dataService:DataService, private configService:ConfigService, private modalService:NgbModal, private localizationService:LocalizationService) { }

    ngOnInit() {
        console.log('TODO: ripristinare relatedResource, relatedElement');
        this.relatedResource = null;
        this.relatedElement = null;

        this.selectionData = [];

        this.resourceName = this.localizationService.translate('RESOURCES.' + this.resourceId + '.name');
        this.viewType = this.configService.getResourceViewConfig(this.resourceId).type;

        this.fileType = null;
        if(this.viewType == 'Files' && this.filterStatus != null && this.filterStatus.filter != null) {
            for(let _cFilter of this.filterStatus.filter) {
                if(_cFilter.field == 'type') {
                    this.fileType = _cFilter.value[0];
                    break;
                }
            }
        }

        this.loadSelectionData();

        let _fieldList = this.configService.getResourceFilterableFields(this.resourceId);

        this.hasTags = _fieldList.indexOf('tags') != -1;
        this.hasSystemTags = _fieldList.indexOf('system_tags') != -1;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if(this.filterStatus == null) this.filterStatus = new PgFilterStatus();
        if(this.selection == null) this.selection = [];

        this.doApplySearch();
    }

    onSearchKeyUp(event:KeyboardEvent) {
        if(event.keyCode == 13) {
            this.doApplySearch();
        }
    }

    doApplySearch() {
        this.appliedFilter = JSON.parse(JSON.stringify(this.filterStatus))
    }

    @ViewChild('modalPicker', { static: true }) modalPicker;

    private _modalRef:NgbModalRef = null;

    modalSelection:Array<string> = null;

    openModal() { 
        this.modalSelection = null;
        if(this.selection != null) this.modalSelection = JSON.parse(JSON.stringify(this.selection))
            
        this._modalRef = this.modalService.open(this.modalPicker, { size: 'lg', windowClass: 'PGResourcePickModal' });
        this._modalRef.result.then(() => {
            this.onSelectionChange(this.modalSelection)
        }, () => {})
    }

    closeModal() {
        this._modalRef.close();
    }

    dismissModal() {
        this._modalRef.dismiss();
    }

    onSelectionChange(data) {        
        this.selection = data;
        this.selectionChange.emit(this.selection);
        this.loadSelectionData();
    }

    isLoading = false;

    loadSelectionData() {
        let _cleanSelection = [];

        if(this.selection != null) {
            for(let _cSelection of this.selection) {
                if(_cSelection != null && _cSelection != '') {
                    _cleanSelection.push(_cSelection)
                }
            }
        }

        if(_cleanSelection.length == 0) {
            this._finishLoadingSelectionData(null);
        }
        else {
            this.isLoading = true;

            if(this.useCache != null) {
                this.useCache.getElementsData(this.resourceId, _cleanSelection).subscribe((data) => {
                    this._finishLoadingSelectionData(data);
                }, () => {
                    this._finishLoadingSelectionData(null);
                })
            }
            else {
                let _cFilterField = 'id';
                if(this.selectionField != null) _cFilterField = this.selectionField;

                this.dataService.getResourceData(this.resourceId, {
                    filter: [new DataFilter(_cFilterField, 'in', _cleanSelection)],
                    limit: 5000
                }).subscribe((data) => {
                    let _cleanResult = []; // TOFIX BE: questa parte serve perché il filtro non filtra bene e mi trovo tutta la resource

                    for(let i = 0; i < _cleanSelection.length; i++) { 
                        for(let _item of data) {
                            if(_item[_cFilterField] == _cleanSelection[i]) {
                                _cleanResult[i] = _item;
                                break;
                            }
                        }

                        if(_cleanResult[i] == null) {
                            _cleanResult[i] = { id: null }
                            _cleanResult[i][_cFilterField] = _cleanSelection[i];
                        }
                    }

                    this._finishLoadingSelectionData(_cleanResult);
                }, () => {
                    this._finishLoadingSelectionData(null);
                })
            }
        }
    }

    private _finishLoadingSelectionData(data:Array<any>) {
        this.isLoading = false;
        
        if(data == null) {
            this.selectionData.splice(0)
        }
        else {
            this.selectionData.splice(data.length)

            for(let i = 0; i < data.length; i++) {
                if(JSON.stringify(data[i]) != JSON.stringify(this.selectionData[i])) {
                    this.selectionData[i] = data[i];
                }
            }
        }
    }
}
