import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigResourceTypes } from '../models/config.resources.model';
import { Socket } from 'ngx-socket-io';
import { PGUtilities } from '../pg-utilities';
import { PgFileUtils } from '../models/file.model';

export class EnvironmentData {
    APIUrl: string = null;
    AppName: string = null;
    AppLogo: string = null;
    AppProvider: string = null;

    AppMode?:'web'|'kiosk'|'hybrid' = null;

    GoogleMapsAPIKey: string = null;
    StripeAPIKey?:string = null;

    AvailableApplicationLanguages?:Array<string> = null;
    DefaultContentLanguages?:Array<string> = null;

    WebSocket: string = null;
    EntouranceURL: string = null;
    WebTourURL: string = null;
    CheckoutURL: string = null;
    FormsURL: string = null;
    ExperienceFinderURL: string = null;
    TotemURL: string = null;

    PrivacyPolicyURL?:string = null;
    TermsAndConditionsURL?:string = null;

    NoDBEnvironment?: boolean = null;
    FileSizeLimit?:number|string = null;
    DisableSMS?:boolean = null;

    RelevantCities?:Array<string> = null;

    ExperienceCertificateOptions?: { title: string, description: string, checks: Array<string> } = null;
    CustomOptionMaps?:{ [resource:string]: { [field:string]: Array<string | { value: string, data:any }> } } = null;

    ImportAPI?:Array<{ name: string, url: string }> = null;
    ImportResources?:Array<string> = null;

    CustomFormConfig?:{
        [resource:string]: {
            [field:string]: ConfigResourceTypes
        }
    } = null;

    ExperienceFinderResources?:Array<string> = null;
    ExperienceFinderGeoJSON?: { [category:string]: string } = null;
    ExperienceFinderSort?:'distance'|'alphabetical'|'shuffle' = null;

    ConnectorsEnabled?:{ [connector:string]: boolean } = null;

    InstallationName?:string = null;
    LoginCodePath?:string = null;

    WebSocketEnabledServices?:{ [service:string]: boolean } = null;
}

export class ApplicationStatus {
    maintenance?:{ from?: string, to?: string }
}

export class ExternalParametersData {
    returnUrl?:string;
    language?:string;
    
    iframe?:string;
    iframekiosk?:string;

    forceEnv?:string;
    
    [x: string]: string;
}

export class RealmData {
    id:string = null;
    name:string = null;
    logo:string = null;
    contacts: {
        email?:string,
        phone?:string,
        website?:string
    } = {}

    constructor(fromData?:any) {
        if(fromData != null) {
            for(let i in fromData) {
                if(typeof this[i] != 'undefined') {
                    if(i == 'contacts') {
                        this[i] = PGUtilities.tryParseJSON(fromData[i])
                        if(this[i] == null) this[i] = {}
                    }
                    else this[i] = fromData[i]
                }
            }
        }
    }
}

export class VersionData {
    major:number
    minor:number
    patch:number
    build?:number
    prefix?:string
    suffix?:string
    time?:string
}

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {
    constructor(private http:HttpClient) {}

    production:boolean = null;

    frontendVersion:VersionData = null;
    apiVersion:string = null;
    websocketVersionLocal:VersionData = null;
    websocketVersionGlobal:VersionData = null;

    environment:EnvironmentData = new EnvironmentData();

    realmList:Array<RealmData> = null;

    applicationStatus:ApplicationStatus = {};
    applicationStatusChange = new Subject<ApplicationStatus>();

    externalParameters:ExternalParametersData = {}

    fileSizeLimitNumber:number = Infinity;
    fileSizeLimitString:string = '';

    ElectronWrapper:{
        getAppEnvironment:() => Promise<any>,
        printContents:() => Promise<void>
    } = null;

    async init(env:any) {
        this.production = env.production;

        await this._loadApplicationVersion();

        // check stato maintenance

        await this._watchApplicationStatus(env);

        if(this.getTimeUntilManteinance() > 0) {

            // carico eventuali parametri esterni

            let _queryParamsSplit:Array<string> = [];
            if(window.location.search != null) _queryParamsSplit = window.location.search.replace(/^\?/, '').split('&');
    
            for(let _cSplit of _queryParamsSplit) {
                let _cSubSplit = _cSplit.split('=');
                this.externalParameters[_cSubSplit[0]] = decodeURIComponent(_cSubSplit[1])
            }
    
            let _envData:any = {}
    
            // vecchia gestione: EnvironmentURL specificato nell'environment.ts
            if(env.EnvironmentURL == null) {
                _envData = env;
            }
            else {
                _envData = await new Promise<any>((resolve, reject) => {
                    this.http.get(env.EnvironmentURL + '?_=' + Date.now()).subscribe((data) => {
                        resolve(data);
                    }, () => {
                        resolve(null);
                    })
                })

                if(_envData == null) _envData = {};
            }
    
            // se non c'è un APIUrl specificato, uso quello di default
            if(_envData.APIUrl == null) {
                console.log('Using default APIUrl')
                _envData.APIUrl = window.location.protocol + '//api.' + window.location.host + '/api'
                console.log(_envData.APIUrl)
            }
    
            this.environment.APIUrl = _envData.APIUrl;

            this.apiVersion = null;
    
            if(_envData.NoDBEnvironment) { // questa flag è usata praticamente solo in sviluppo per forzare il caricamento dell'enviroment da environment.ts
                await new Promise<void>((resolve, reject) => {
                    this.http.head(this.environment.APIUrl + '/environment', { observe: 'response' }).subscribe((data) => {
                        this.apiVersion = data.headers.get('api-version')
                        resolve();
                    }, () => {
                        resolve(null);
                    })
                })
                
            }
            else {
                // carico l'environment da DB

                let _envDBData = await new Promise<any>((resolve, reject) => {
                    this.http.get(this.environment.APIUrl + '/environment?limit=1000', { observe: 'response' }).subscribe((data) => {
                        this.apiVersion = data.headers.get('api-version')
                        resolve(data.body);
                    }, () => {
                        resolve(null);
                    })
                })
    
                if(_envDBData != null) {
                    for(let _envItem of _envDBData) {
                        if(_envItem.data != null) {
                            try {
                                if(_envItem.name != 'AppMode' || _envData[_envItem.name] != 'hybrid') { // NB: l'AppMode devo prenderlo preferenzialmente dal file environment per le app hybrid
                                    _envData[_envItem.name] = _envItem.data.value
                                }
                            }
                            catch(ex) {}
                        }
                    }
                }
            }
    
            _envData = this._checkDataMigrations(_envData)
    
            // log setting di environment non riconosciuti

            for(let i in _envData) {
                if(typeof this.environment[i] == 'undefined') {
                    console.log('Found unused environment setting: ' + i, _envData[i])
                }
                else {
                    this.environment[i] = _envData[i];
                }
            }
    
            if(this.environment.AppMode == null) {
                this.environment.AppMode = 'web';
            }

            if(this.environment.WebSocketEnabledServices == null) {
                this.environment.WebSocketEnabledServices = { 'tour': true, 'user-status': true }
            }
            
            if(this.environment.FileSizeLimit != null) {
                if(typeof this.environment.FileSizeLimit == 'number') {
                    this.fileSizeLimitNumber = this.environment.FileSizeLimit;
                }
                else if(typeof this.environment.FileSizeLimit == 'string') {
                    this.fileSizeLimitNumber = PgFileUtils.getFileSizeNumber(this.environment.FileSizeLimit)
                }
    
                this.fileSizeLimitString =  PgFileUtils.getFileSizeString(this.fileSizeLimitNumber)
    
                console.log('FileSizeLimit', this.fileSizeLimitString, this.fileSizeLimitNumber)
            }

            // controllo se sono nell'ElectronWrapper

            this.ElectronWrapper = window['ElectronWrapper'];

            if(this.ElectronWrapper != null) {
                console.log('Found ElectronWrapper')

                let _electronAppEnvironment = await this.ElectronWrapper.getAppEnvironment()

                if(_electronAppEnvironment != null) {
                    console.log('Using ElectronWrapper AppEnvironment', _electronAppEnvironment)

                    for(let i in _electronAppEnvironment) {
                        this.environment[i] = _electronAppEnvironment[i];
                    }
                }
            }
            else {
                if(this.externalParameters.forceElectron != null) {
                    console.log('Forcing ElectronWrapper')
                    
                    this.ElectronWrapper = {
                        getAppEnvironment: () => {
                            return new Promise((resolve) => {
                                resolve({})
                            })
                        },
                        printContents: () => {
                            return new Promise((resolve) => {
                                resolve()
                            })
                        },
                    }
                }
            }
    
            if(!this.production) {
                console.warn('DevMode Active')
    
                if(this.externalParameters.forceEnv != null) {
                    // se sono in modalità sviluppo carico eventualmente le variabili di environment forzate tramite query string
                    
                    let _cObj = JSON.parse(this.externalParameters.forceEnv)
    
                    for(let i in _cObj) {
                        this.environment[i] = _cObj[i]
                    }
                }
            }
    
            console.log('Environment loaded', this.environment)

            if(this.frontendVersion != null) {
                console.log('Frontend ver. ' + this.getVersionReport(this.frontendVersion))
            }
            
            if(this.apiVersion != null) {
                console.log('API ver. ' + this.apiVersion)
            }

            if(this.environment.WebSocket != null) {
                let _socket = new Socket({ 
                    url: this.environment.WebSocket, 
                    options: {} 
                })
    
                _socket.on('version', (data) => { 
                    this.websocketVersionLocal = data.local;
                    this.websocketVersionGlobal = data.global;

                    if(this.websocketVersionLocal != null) {
                        console.log('Websocket Local ver. ' + this.getVersionReport(this.websocketVersionLocal))
                    }

                    if(this.websocketVersionGlobal != null) {
                        console.log('Websocket Global ver. ' + this.getVersionReport(this.websocketVersionGlobal))
                    }
                });
            }

            let _realmsData = await new Promise<any>((resolve, reject) => {
                this.http.get(this.environment.APIUrl + '/public/realm?limit=1000').subscribe((data) => {
                    resolve(data);
                }, () => {
                    resolve(null);
                })
            })

            this.realmList = [];
            
            for(let _realm of _realmsData) {
                this.realmList.push(new RealmData(_realm))
            }
        }
    }

    _loadApplicationVersion() {
        return new Promise<void>((resolve) => {
            this.http.get('assets/release.json', {
                headers: { 'X-Interceptor-Silent': '*' }
            }).pipe(catchError(() => {
                return of(null as Object)
            })).subscribe((data:any) => {
                this.frontendVersion = data;

                resolve()
            })
        })
    }

    _watchApplicationStatus(env:any) {        
        setInterval(() => {
            this._loadApplicationStatus(env).then(() => {}, () => {})
        }, 60000)

        return this._loadApplicationStatus(env)
    }

    _loadApplicationStatus(env:any) {
        return new Promise<void>((resolve) => {
            if(env.StatusURL == null) {
                this._handleApplicationStatus({})

                resolve()
            }
            else {
                this.http.get(env.StatusURL + '?_=' + Date.now(), {
                    headers: { 'X-Interceptor-Silent': '*' }
                }).pipe(catchError(() => {
                    return of({} as Object)
                })).subscribe((data) => {
                    
                    /*data = { 
                        maintenance: { 
                            from: new Date(Date.now() + 1250100).toISOString(), 
                            //to: new Date(Date.now() + 1910000).toISOString() 
                        } 
                    }*/

                    this._handleApplicationStatus(data)

                    resolve()
                })
            }
        })
    }

    private _handleApplicationStatus(data:any) {
        if(JSON.stringify(this.applicationStatus) != JSON.stringify(data)) { // NB: questo compare andrebbe fatto meglio
            this.applicationStatus = data;
            this.applicationStatusChange.next(this.applicationStatus)
        }
    }

    private _checkDataMigrations(data:any) {
        if(data.DashboardConfig != null) {
            console.log('Found obsolete DashboardConfig setting')

            if(data.RelevantCities == null && data.DashboardConfig.widgets != null) {
                for(let _widget of data.DashboardConfig.widgets) {
                    if(_widget.name == 'Weather') {
                        console.log('Migrating to RelevantCities')
                        data.RelevantCities = _widget.params.locations;

                        break;
                    }
                }
            }
        }

        return data;
    }

    hasRealms() {
        return (this.realmList != null && this.realmList.length > 0)
    }

    publicRealm:RealmData = null;
    publicRealmChange = new Subject<RealmData>();

    setPublicRealm(idOrName:string) {
        let _nextRealm:RealmData = null;

        for(let _realm of this.realmList) {
            if(_realm.id == idOrName || _realm.name == idOrName) {
                _nextRealm = _realm;
                break;
            }
        }

        if(this.publicRealm != _nextRealm) {
            this.publicRealm = _nextRealm;
            this.publicRealmChange.next(this.publicRealm);
        }
    }

    getRealm(id:string) {
        for(let _realm of this.realmList) {
            if(id == _realm.id) {
                return _realm;
            }
        }

        for(let _realm of this.realmList) {
            if(id == _realm.name) {
                return _realm;
            }
        }

        for(let _realm of this.realmList) {
            if(id == _realm.id + ' - ' + _realm.name) {
                return _realm;
            }
        }
    }

    getRealmName(id:string, withId?:boolean) {
        if(id == null) return 'null';
        else {
            let _realm = this.getRealm(id)
            if(_realm != null) {
                return (withId ? id + ' - ' : '') + _realm.name;
            }
        }
    }

    getVersionString(version?:VersionData) {
        if(version == null) version = this.frontendVersion;

        if(version != null) {
            let _versionString = version.major + '.' + version.minor + '.' + version.patch;
            if(version.prefix != null) _versionString = version.prefix + '-' + _versionString;
            if(version.suffix != null) _versionString = _versionString + '-' + version.suffix;

            return _versionString;
        }
    }

    getBuildTime(version?:VersionData) {
        if(version == null) version = this.frontendVersion;
        
        if(version != null) {
            return version.time;
        }
    }

    getBuildTimeString(version?:VersionData) {
        return new Date(this.getBuildTime(version)).toLocaleString(undefined, { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" })
    }

    getVersionReport(version?:VersionData) {
        if(version == null) version = this.frontendVersion;
        
        if(version != null) {
            return this.getVersionString(version) + ' - ' + this.getBuildTimeString(version);
        }
    }

    getVersionsTable(plain?:boolean) {
        let _versionIndex:{ [id:string]: string|VersionData } = {
            'API': this.apiVersion, 
            'Frontend': this.frontendVersion, 
            'Websocket Local': this.websocketVersionLocal, 
            'Websocket Global': this.websocketVersionGlobal
        }

        let _retVal = '';

        for(let i in _versionIndex) {
            let _version = _versionIndex[i];

            if(plain) {
                if(_retVal != '') _retVal += '\r\n ';

                _retVal += i + ': ';

                if(typeof _version == 'string') {
                    _retVal += _version;
                }
                else {
                    _retVal += this.getVersionString(_version)

                    let _time = this.getBuildTimeString(_version)
                    if(_time != null) {
                        _retVal += ' - ' + _time;
                    }
                }
            }
            else {
                if(_retVal != '') {
                    _retVal += '<tr><td colspan="2"><div class="my-1"></div></td></tr>'
                }
    
                _retVal += '<tr><td>' + i + '</td>';
    
                if(typeof _version == 'string') {
                    _retVal += '<td class="ps-2">' + _version + '</td></tr>'
                }
                else {
                    _retVal += '<td class="ps-2">' + this.getVersionString(_version) + '</td></tr>'
    
                    let _time = this.getBuildTimeString(_version)
                    if(_time != null) {
                        _retVal += '<tr><td colspan="2" class="text-muted small">' + _time + '</td></tr>'
                    }
                }  
            }
        }

        if(!plain) {
            _retVal = '<table class="PGVersionTable">' + _retVal + '</table>'; 
        }

        return _retVal;
    }

    getTimeUntilManteinance() {
        if(this.applicationStatus.maintenance != null) {
            if(this.applicationStatus.maintenance.from == null) return 0;
            else return(new Date(this.applicationStatus.maintenance.from).getTime() - Date.now())
        }
        else return Infinity;
    }

    isConnectorEnabled(connector:string) {
        return this.environment.ConnectorsEnabled != null && this.environment.ConnectorsEnabled[connector];
    }

    getPrivacyPolicyURL() {
        if(this.environment.PrivacyPolicyURL != null) return this.environment.PrivacyPolicyURL;
        else return this.environment.ExperienceFinderURL + '/content/ENTOURANCE.privacy-policy'
    }

    getTermsAndConditionsURL() {
        if(this.environment.PrivacyPolicyURL != null) return this.environment.PrivacyPolicyURL;
        else return this.environment.ExperienceFinderURL + '/content/ENTOURANCE.terms-and-conditions'
    }

    isWebsocketServiceEnabled(service:string) {
        return this.environment.WebSocket != null && this.environment.WebSocketEnabledServices != null && this.environment.WebSocketEnabledServices[service]
    }
}