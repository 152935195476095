import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ConfigProfileRoleActionsExtended, ConfigService } from '../../services/config.service';
import { ConfigProfileRole } from '../../models/config.profiles.model';
import { EnvironmentService } from '../../services/environment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { NotificationsService } from '../../services/notifications.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PgUserPickerComponent } from '../pg-user-picker/pg-user-picker.component';
import { PgGroupPickerComponent } from '../pg-group-picker/pg-group-picker.component';
import { PgPriorityPickerComponent } from '../pg-priority-picker/pg-priority-picker.component';
import { LocalizationService } from '../../services/localization.service';
import { AuthService } from '../../services/auth.service';
import { PgRealmPickerComponent } from '../pg-realm-picker/pg-realm-picker.component';
import { PgTagPickerComponent } from '../pg-tag-picker/pg-tag-picker.component';
import { PgSelectPickerComponent } from '../pg-select-picker/pg-select-picker.component';
import { OptionMapsService } from '../../services/option-maps.service';
import { PGUtilities } from '../../pg-utilities';

@Component({
  selector: 'app-pg-resource-element-menu',
  templateUrl: './pg-resource-element-menu.component.html',
  styleUrls: ['./pg-resource-element-menu.component.scss']
})
export class PgResourceElementMenuComponent implements OnInit, OnChanges {

    constructor(private environmentService:EnvironmentService, private configService:ConfigService, private dataService:DataService, private localizationService:LocalizationService, private notificationsService:NotificationsService, private router:Router, private route:ActivatedRoute, private modalService:NgbModal, private authService:AuthService, private optionMapsService:OptionMapsService) { }

    @Input() resourceId:string;
    @Input() elementId:string;

    @Input() relatedResource:string;
    @Input() relatedElement:string;

    @Input() elementData:any;

    @Input() baseURL:string;
    @Input() readOnly:boolean;

    @Input() isLarge:boolean;

    @Output() dataChange = new EventEmitter<{ data: any, changes: any }>();

    @Input() customActions:{ [action:string]: { label?: string, action?: boolean } };

    @Output() customActionRun = new EventEmitter<string>();

    actions:ConfigProfileRoleActionsExtended = null;
    permissions:ConfigProfileRole = null;

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.actions = this.configService.getResourceActionsExtended(this.resourceId);

        if(this.readOnly) {
            for(let i in this.actions) {
                if(this.actions[i]) {
                    if(i != 'view' && i != 'list' && i != 'book') {
                        this.actions[i] = false;
                    }
                }
            }
        }

        this.permissions = this.configService.getResourcePermissions(this.resourceId);
    }

    isElementProtected() {
        return this.configService.isElementProtected(this.resourceId, this.elementData)
    }

    hasExtendedActions() {
        for(let i in this.actions) {
            if(i != 'view' && i != 'list' && this.actions[i]) return true;
        }
    }

    getBaseURL() {
        if(this.baseURL == null) return '../..'
        else return this.baseURL
    }

    getActionLabel(action:string) {
        if(this.customActions != null && this.customActions[action] != null && this.customActions[action].label != null) {
            return this.customActions[action].label
        }
        else {
            return 'pg-resource-element-menu.action-' + action
        }
    }

    isRunningAction = false;

    runAction(action:string, value?:any, connector?:any) {
        if(this.customActions != null && this.customActions[action] != null && this.customActions[action].action) {
            this.customActionRun.emit(action)
        }
        else {
            switch(action) {
                case 'book': 
                    window.open(this.environmentService.environment.CheckoutURL + '/book-' + this.resourceId.toLowerCase() + '/' + this.elementId); 
                    break;
                case 'edit': 
                    this.router.navigate([this.getBaseURL() + '/form/' + this.resourceId + '/' + this.elementId], { relativeTo: this.route }); 
                    break;
                case 'delete': 
                    this.dataService.deleteElement(this.resourceId, this.elementId).subscribe((data) => {
                        this.handleDataChange(null, null)
                    })
                    break;
                case 'user':
                    this.changeUser(value);
                    break;
                case 'group':
                    this.changeGroup(value);
                    break;
                case 'realm':
                    this.changeRealm(value);
                    break;
                case 'category':
                    this.changeCategory(value);
                    break;
                case 'categories':
                    this.changeCategories(value);
                    break;
                case 'system_tags':
                    this.changeSystemTags(value);
                    break;
                case 'tags':
                    this.changeTags(value);
                    break;
                case 'priority':
                    this.changePriority(value);
                    break;
                case 'bookable': {
                    let _putData = { bookable: value };
                    this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                        this.handleDataChange(data, _putData)
                    })
                    break;
                }
                case 'published': {
                    let _putData = { published: value };
                    this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                        this.handleDataChange(data, _putData)
                    })
                    break;
                }
                case 'connector-publish':
                    this.isRunningAction = true;
                    this.dataService.doPublishOn(connector, this.resourceId, this.elementId).subscribe((data) => {
                        this.isRunningAction = false;

                        if(data != null) {
                            let _putData = { connector: connector };
                            this.notificationsService.addLocalNotification(this.localizationService.translate('pg-resource-element-menu.connector-published', _putData), 'success', null, null, 3000)
                            this.handleDataChange(data, _putData)
                        }
                    })
                    break;
                case 'template':
                    this.createTemplate()
                    break;
            }
        }
    }

    createTemplate() {
        let _templateData = JSON.parse(JSON.stringify(this.elementData));
        let _templateResourceId = this.resourceId += '~Template'
        _templateData.template = true;

        this.dataService.createDraftElement(_templateResourceId, _templateData).subscribe(data => {
            this.router.navigate([this.getBaseURL() +'/form/' + _templateResourceId + '/' + data.id], { relativeTo: this.route });
        });
    }

    handleDataChange(data:any, changes:any) {
        this.dataChange.emit({ data: data, changes: changes })
    }

    changeUser(value:string) {
        let _modalRef = this.modalService.open(PgUserPickerComponent);
        
        (_modalRef.componentInstance as PgUserPickerComponent).selectedValue = value;

        _modalRef.result.then((value) => {
            let _putData = { user_id: value };
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    changeGroup(value:string) {
        let _modalRef = this.modalService.open(PgGroupPickerComponent);
        
        (_modalRef.componentInstance as PgGroupPickerComponent).selectedValue = value;

        _modalRef.result.then((value) => {
            let _putData = { group_id: value };
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    changePriority(value:string) {
        let _modalRef = this.modalService.open(PgPriorityPickerComponent);
        
        (_modalRef.componentInstance as PgPriorityPickerComponent).selectedValue = value;

        _modalRef.result.then((value) => {
            let _putData = { priority: value };
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    changeCategory(value:string) {
        let _modalRef = this.modalService.open(PgSelectPickerComponent);
        
        let _componentInstance = (_modalRef.componentInstance as PgSelectPickerComponent)
        _componentInstance.label = this.localizationService.translateResourceField(this.resourceId, 'category')
        _componentInstance.options = this.optionMapsService.getResourceFieldOptionMap(this.resourceId, 'category')
        _componentInstance.selectedValue = value;

        _modalRef.result.then((value) => {
            let _putData = { category: value };
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    changeCategories(value:string) {
        let _modalRef = this.modalService.open(PgSelectPickerComponent);
        
        let _componentInstance = (_modalRef.componentInstance as PgSelectPickerComponent)
        _componentInstance.label = this.localizationService.translateResourceField(this.resourceId, 'categories')
        _componentInstance.options = this.optionMapsService.getResourceFieldOptionMap(this.resourceId, 'categories')
        _componentInstance.multi = true;
        _componentInstance.selectedValue = value;

        _modalRef.result.then((value) => {
            let _putData = { categories: value };
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    changeSystemTags(value:string) {
        let _modalRef = this.modalService.open(PgSelectPickerComponent);
        
        let _componentInstance = (_modalRef.componentInstance as PgSelectPickerComponent)
        _componentInstance.label = this.localizationService.translateResourceField(this.resourceId, 'system_tags')
        _componentInstance.options = this.optionMapsService.getFixedOptionMap('*', 'system_tags')
        _componentInstance.multi = true;
        _componentInstance.selectedValue = value;

        _modalRef.result.then((value) => {
            let _putData = { system_tags: value };
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    changeTags(value:string) {
        let _modalRef = this.modalService.open(PgTagPickerComponent, { size: 'lg' });
        
        (_modalRef.componentInstance as PgTagPickerComponent).selectedValue = value;

        _modalRef.result.then((value) => {
            let _putData = { tags: value };
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    canBePublished() {
        return this.configService.isElementPublishable(this.resourceId, this.elementData)
    }

    getGroupLabel(id:string) {
        for(let _group of this.configService.groups) {
            if(_group.id == id) return _group.label
        }
    }

    canPublishOn(connector:string) {
        return this.dataService.canPublishOn(connector, this.resourceId);
    }

    canBePublishedOn(connector:string) {
        return this.configService.isElementPublishableOn(connector, this.resourceId, this.elementData);
    }

    hasPublishWarning(connector:string) {
        if(connector == 'DHM' && this.resourceId == 'Itinerary' && this.elementData.collection != null) {
            for(let _item of this.elementData.collection) {
                if(_item.resource != 'Poi') return true;
            }
        }
    }

    getLastPublished(connector:string) {
        let _prop = this.elementData['Connected' + connector]
        if(typeof _prop == 'string') {
            return _prop
        }
    }

    canEditField(field:string) {
        return this.actions.edit && this.permissions.fields[field] && this.permissions.fields[field].edit;
    }

    getCategoryLabel(id:string) {
        if(id != null) {
            return this.localizationService.translate('OPTIONMAPS.' + this.getCleanResourceId() + '.category.' + id)
        }
    }

    getCategoriesLabel(id:string) {
        let _parsed = PGUtilities.tryParseJSON(id)
        if(_parsed != null && _parsed.length > 0) {
            let _retVal = ''

            for(let _item of _parsed) {
                if(_retVal != '') _retVal += ', '
                _retVal += this.localizationService.translate('OPTIONMAPS.' + this.getCleanResourceId() + '.categories.' + _item)
            }

            return _retVal;
        }
    }

    getPriorityLabel(id:string) {
        if(id != null) {
            return id + ' - ' + this.localizationService.translate('OPTIONMAPS.*.priority.' + id)
        }
    }


    getSystemTagsLabel(id:string) {
        let _parsed = PGUtilities.tryParseJSON(id)
        if(_parsed != null && _parsed.length > 0) {
            let _retVal = ''

            for(let _item of _parsed) {
                if(_retVal != '') _retVal += ', '
                _retVal += this.localizationService.translate('OPTIONMAPS.*.system_tags.' + _item)
            }

            return _retVal;
        }
    }

    getUserLabel(id:string, data?:any) {
        let _retVal = id;

        if(data != null) {
            let _name = '';

            if(data.name) _name += data.name;
            if(data.surname) {
                if(_name != '') _name += ' '
                _name += data.surname;
            }

            if(_name != '') {
                _retVal += ' - ' + _name;
            }
        }

        return _retVal;
    }

    getCleanResourceId() {
        return this.dataService.getCleanResourceId(this.resourceId)
    }


    canActionRealm() {
        return this.actions.realm && this.authService.user.availableRealms.length > 1
    }

    getRealmLabel(id:string) {
        return this.environmentService.getRealmName(id)
    }

    changeRealm(value:string) {
        let _modalRef = this.modalService.open(PgRealmPickerComponent);
        
        (_modalRef.componentInstance as PgRealmPickerComponent).selectedValue = value;

        let _putData = { realm_id: value }

        _modalRef.result.then((value) => {
            this.dataService.putElementData(this.resourceId, this.elementId, _putData).subscribe((data) => {
                this.handleDataChange(data, _putData)
            })
        }, () => {

        })
    }

    hasChangeRealmWarning() {
        return this.resourceId == 'Directory' && this.elementData.parent_id != null;
    }
}
