import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { 
    ControlValueAccessor, 
    NG_VALUE_ACCESSOR, 
    Validator, 
    NG_VALIDATORS 
} from '@angular/forms';
import { DataFilter, DataService, PgFilterStatus } from '../../services/data.service';
import { PgFile, PgFileType, PgFileUtils } from '../../models/file.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PgImageCropModalComponent } from '../../pg-ui-elements/pg-image-crop-modal/pg-image-crop-modal.component';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../services/config.service';

class PgFilePickerSelected {
    progress?:number;
    error?:string;

    constructor(public file: File) {}
}

@Component({
  selector: 'app-pg-file-picker',
  templateUrl: './pg-file-picker.component.html',
  styleUrls: ['./pg-file-picker.component.scss'],
  providers: [{
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => PgFilePickerComponent),
      multi: true
  }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PgFilePickerComponent),
      multi: true,
  }]
})
export class PgFilePickerComponent implements ControlValueAccessor, Validator, OnInit, OnChanges {

    constructor(private dataService:DataService, private modalService:NgbModal, private configService: ConfigService) { }

    ngOnInit(): void {}

    @Input() fieldId:string;

    @Input() readonly: boolean;

    @Input() required: boolean;

    @Input() multi: boolean;

    @Input() fileType:PgFileType;
    @Input() fileOptions:any;

    @Input() groupId:string;
    @Input() realmId:string;

    @Input() extraActions:Array<{
        name:string,
        color:string,
        icon:string
    }>;

    @Output() onExtraAction = new EventEmitter<{ action: string, file: string }>()

    acceptString:string = null;

    autoConfirm = true;

    resourcePickerFilterStatus:PgFilterStatus = null;

    canSelectFile = false;

    ngOnChanges() {
        if(this.fileOptions != null && this.fileOptions.acceptString != null) {
            this.acceptString = this.fileOptions.acceptString;
        }
        else {
            this.acceptString = null;
        }

        this.resourcePickerFilterStatus = new PgFilterStatus()

        if(this.fileType != null) {
            this.resourcePickerFilterStatus.filter = [{
                field: 'type',
                operator: '==',
                value: [ this.fileType ]
            }]
        }

        this.canSelectFile = this.configService.getResourceActions('File')?.list;
    }

    isLoading = false;

    alreadyLoaded:Array<PgFile> = null;
    selectedFiles:Array<PgFilePickerSelected> = null;

    value:File|Array<File> = null;

    setFiles(files:Array<File>) {

        if(this.selectedFiles == null || !this.multi) {
            this.selectedFiles = [];
        }
        
        for(let i = 0; i < files.length; i++) {
            this.selectedFiles.push({ file: files[i] })
        }

        if(this._onTouched != null) this._onTouched();

        if(this.autoConfirm) this.saveFiles();
    }

    hasChanges() {
        for(let _cLoaded of this.alreadyLoaded) {
            if(_cLoaded.deleted) return true;
        }

        return this.selectedFiles != null && this.selectedFiles.length > 0;
    }

    getButtonIcon() {
        return PgFileUtils.getCategoryIcon(this.fileType)
    }

    toggleAlreadyUploadedDelete(file:PgFile) {
        file.deleted = !file.deleted;
        if(this._onTouched != null) this._onTouched();

        if(this.autoConfirm) this.saveFiles();
    }

    removeSelectedFile(file:PgFilePickerSelected) {
        this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
    }

    private _modalImageCropRef:NgbModalRef = null;

    modalImageCropTarget:PgFilePickerSelected = null;

    openImageCropModal(file:PgFilePickerSelected) { 
        this.modalImageCropTarget = file;

        this._modalImageCropRef = this.modalService.open(PgImageCropModalComponent);
        let _cComponentInstance = this._modalImageCropRef.componentInstance as PgImageCropModalComponent;

        let _cObjectURL = URL.createObjectURL(file.file);
        _cComponentInstance.imageURL = _cObjectURL;

        if(this.fileOptions != null && this.fileOptions.imageRatio != null) {
            _cComponentInstance.cropperSettings.width = this.fileOptions.imageRatio.width;
            _cComponentInstance.cropperSettings.height = this.fileOptions.imageRatio.height;
            _cComponentInstance.cropperSettings.keepAspect = true;
        }
        
        this._modalImageCropRef.result.then((data) => {
            URL.revokeObjectURL(_cObjectURL)

            var byteString = atob(data.split(',')[1]);

            var ab = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(ab);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            this.modalImageCropTarget.file = new File([ab], this.modalImageCropTarget.file.name)
        },() => {
            URL.revokeObjectURL(_cObjectURL)
        })
    }

    resourcePickerSelection:Array<string> = null;

    onResourcePickerSelectionChange() {
        if(this._onTouched != null) this._onTouched();

        this.alreadyLoaded = [];
        this.loadFiles(this.resourcePickerSelection, true)
    }

    // INTERFACCIA ControlValueAccessor

    writeValue(obj:string) {
        this.alreadyLoaded = [];
        this.resourcePickerSelection = [];

        if(obj != null && obj != '') {
            let _cValues = [obj];

            if(this.multi) {
                _cValues = JSON.parse(obj)
                for(let i = _cValues.length - 1; i >= 0; i--) {
                    if(_cValues[i] == null) _cValues.splice(i, 1)
                }
            }

            this.resourcePickerSelection = _cValues;
            this.loadFiles(_cValues)
        }
    }

    private _getFileNameFromUrl(url:string) {
        if(url != null) {
            let _fileNameMatch = url.match(/\/([^\/]*)$/)
            if(_fileNameMatch != null) {
                return _fileNameMatch[1];
            }
        }
    }

    private _loadFileSubscription:Subscription = null;

    loadFiles(urlList:Array<string>, andTriggerChange?:boolean) {
        this.isLoading = true;

        let _fileNameList:Array<string> = [];
        for(let _url of urlList) {
            let _fileName = this._getFileNameFromUrl(_url)
            if(_fileName != null) {
                _fileNameList.push(_fileName)
            }
        }

        if(this._loadFileSubscription != null) this._loadFileSubscription.unsubscribe();

        this._loadFileSubscription = this.dataService.getResourceData('File', {
            filter: [new DataFilter('nome_file', 'in', _fileNameList)]
        }).subscribe((data) => {
            this._loadFileSubscription = null;

            for(let _url of urlList) {
                let _wasFound = false;

                for(let _cData of data) {
                    if(_cData.url == _url) {
                        this.alreadyLoaded.push(new PgFile(_cData))
                        _wasFound = true;
                        break;
                    }
                }

                if(!_wasFound) {
                    let _fileName = this._getFileNameFromUrl(_url)

                    if(_fileName != null) {
                        this.alreadyLoaded.push(new PgFile({
                            nome_file: _fileName,
                            url: _url,
                            nome: _fileName.replace(/\.[^\.]*$/, '')
                        }))
                    }
                }
            }

            this.isLoading = false;

            if(andTriggerChange) {
                this._finishSave()
            }
        })
    }

    isSaving = false;

    saveFiles() {
        this.isSaving = true;

        let _reqNum = 0;

        for(let _cLoaded of this.alreadyLoaded) {
            if(_cLoaded.deleted || !this.multi) {
                _reqNum++;

                //NB: non cancello mai i file, li rimuovo solo dai riferimenti
                //this.dataService.deleteElement('File', _cLoaded.id).subscribe((data) => {
                setTimeout(() => {
                    this.alreadyLoaded.splice(this.alreadyLoaded.indexOf(_cLoaded), 1)

                    _reqNum--;
                    if(_reqNum <= 0) {
                        this._finishSave();
                    }
                })
                //})
            }
        }

        if(this.selectedFiles != null) {
            for(let i = this.selectedFiles.length - 1; i >= 0; i--) {
                if(this.selectedFiles[i].error != null) {
                    this.selectedFiles.splice(i, 1);
                }
            }

            for(let _cSelected of this.selectedFiles) {
                _reqNum++;
                this.dataService.postFile('File', _cSelected.file, this.groupId, this.realmId, true).subscribe((progress) => {
                    _cSelected.progress = progress.progress;
    
                    if(progress.error) {
                        if(progress.error.status == 422) {
                            _cSelected.error = 'Format not supported';
                        }
                        else {
                            _cSelected.error = 'Upload failed';
                        }
                        
                        _reqNum--;
                    }
                    else if(progress.complete) {
                        this.alreadyLoaded.push(new PgFile(progress.data));
                        this.selectedFiles.splice(this.selectedFiles.indexOf(_cSelected), 1)
                        _reqNum--;
                    }
    
                    if(_reqNum <= 0) {
                        this._finishSave();
                    }
                })
            }
        }
    }

    private _finishSave() {
        this.isSaving = false;

        let _cVal = null;

        if(this.multi) {
            for(let _cFile of this.alreadyLoaded) {
                if(_cVal == null) _cVal = [];
    
                _cVal.push(_cFile.url);
            }

            if(this._onChange != null) {
                this._onChange(_cVal == null ? _cVal : JSON.stringify(_cVal));
            }
        }
        else {
            if(this.alreadyLoaded[0] != null) {
                _cVal = this.alreadyLoaded[0].url;
            }

            if(this._onChange != null) {
                this._onChange(_cVal);
            }
        }
    }

    _onChange;

    registerOnChange(fn: any) {
        this._onChange = fn;
    }

    _onTouched;

    registerOnTouched(fn: any) {
        this._onTouched = fn;
    }

    // INTERFACCIA Validator

    validate()  {
        return (!this.required || this.value != null)  ? null : {
            required: {
                valid: false
            }
        }
    };
}
