import { Component, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PGViewResourceInfiniteComponent } from '../resource-view-common';
import { DataFilter, DataService, GetResourceDataOptions } from '../../services/data.service';
import { ConfigService } from '../../services/config.service';
import { SemanticsService } from '../../services/semantics.service';
import { EnvironmentService } from '../../services/environment.service';
import { PgDirectoryData, PgFile, PgFileData, PgFileType, PgFileUtils } from '../../models/file.model';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService } from '../../services/notifications.service';
import { AuthService } from '../../services/auth.service';
import { ConfigProfileRoleActions } from '../../models/config.profiles.model';
import { PGUtilities } from '../../pg-utilities';

@Component({
  selector: 'app-pg-resource-view-files',
  templateUrl: './pg-resource-view-files.component.html',
  styleUrls: ['./pg-resource-view-files.component.scss']
})
export class PgResourceViewFilesComponent extends PGViewResourceInfiniteComponent {
    dataRows:Array<PgFile>;

    constructor(dataService: DataService, configService: ConfigService, semanticsService:SemanticsService, router:Router, route:ActivatedRoute, environmentService:EnvironmentService, modalService: NgbModal, localizationService:LocalizationService, notificationsService:NotificationsService, private authService:AuthService) {
        super(dataService, configService, semanticsService, router, route, environmentService, modalService, localizationService, notificationsService);
    }

    directoryActions:ConfigProfileRoleActions = null;

    ngOnInit(): void {
        super.ngOnInit()

        this.directoryActions = this.configService.getResourceActions('Directory')

        if(this.hasDirectoriesNavigation()) {
            this.initCurrentDirectory(this.route.snapshot.fragment).then(() => {
                this.loadCurrentDirectory()
            })
        }
    }

    initCurrentDirectory(id:string) {
        return new Promise<void>((resolve, reject) => {
            if(this.hasSearchOrTags()) {
                id = null;
            }

            if(id == null || id == '') {
                resolve()
            }
            else {
                this.isLoadingDirectoryData = true;

                this.dataService.getResourceData('Directory', {
                    limit: 1000,
                    filter: [{
                        field: 'id',
                        operator: '==',
                        value: [id]
                    }]
                }).subscribe((data) => {
                    this.currentDirectory = data[0];

                    if(this.currentDirectory.path == null) {
                        resolve()
                    }
                    else {
                        let _pathSplit = this.currentDirectory.path.replace(/^\//, '').replace(/\/$/, '').split('/')

                        if(_pathSplit.length == 0) resolve();
                        else {
                            this.dataService.getResourceData('Directory', {
                                limit: 1000,
                                filter: [{
                                    field: 'id',
                                    operator: 'in',
                                    value: _pathSplit
                                }]
                            }).subscribe((data) => {
                                for(let _id of _pathSplit) {
                                    for(let _item of data) {
                                        if(_item.id == _id) {
                                            this.currentDirectoryPath.push(_item)
                                            break;
                                        }
                                    }
                                }
        
                                resolve()
                            })
                        }
                    }
                })
            }
        })
    }

    viewMode: string = null;

    pageSize = 12;

    setViewMode(viewMode) {
        this.viewMode = viewMode;

        this.reset();
    }

    beforeGetData(options:GetResourceDataOptions) {
        if(!this.hasSearchOrTags()) {
            options.filter = [{ field: 'directory_id', operator: '==', value: [ this.currentDirectory?.id ] }];
        }

        if(this.viewMode != null) {
            options.filter.push({ field: 'type', operator: '==', value: [ this.viewMode ] })
        }

        if(options.order == null) options.order = [];
        
        if(options.order.length == 0) options.order.push({
            direction: 'desc',
            field: 'id'
        })

        return options;
    }

    onLoadData(data:Array<any>) {
        return new Observable<any>((observer) => {
            let _filesData = [];

            for(let _cData of data) {
                _filesData.push(new PgFile(_cData))
            }

            observer.next(_filesData);
            observer.unsubscribe();
        })
    }

    getLayoutMode() {
        if(this.viewMode == 'images' || this.viewMode == 'videos' || this.viewMode == 'audios') return this.viewMode.toUpperCase();
        else return 'FILES'
    }

    createFile() {
        this.configService.getResourceNewElement(this.resourceId, this.relatedResource, this.relatedElement).subscribe((newData) => {
            if(this.currentDirectory != null) {
                newData.directory_id = this.currentDirectory.id;
            }

            this.dataService.createDraftElement(this.resourceId, newData).subscribe(data => {
                this.router.navigate([this.getBaseURL() +'/form/' + this.resourceId + '/' + data.id], { relativeTo: this.route });
            });
        })
    }

    getCategoryIcon(type:PgFileType) {
        return PgFileUtils.getCategoryIcon(type)
    }

    ngOnChanges() {
        super.ngOnChanges();

        this.viewMode = null;

        if(this.filterStatus != null) {
            for(let _filter of this.filterStatus.filter) {
                if(_filter.field == 'type' && _filter.operator == '==') {
                    this.viewMode = _filter.value[0];
                    return;
                }
            }
        }
    }

    hasViewModeSelection() {
        if(this.filterStatus != null) {
            for(let _filter of this.filterStatus.filter) {
                if(_filter.field == 'type') return false;
            }
        }

        return true;
    }

    // GESTIONE DIRECTORY

    currentDirectory:PgDirectoryData = null;

    currentDirectoryPath:Array<PgDirectoryData> = [];
    currentDirectoryList:Array<PgDirectoryData> = null;

    hasDirectoriesNavigation() {
        return this.directoryActions?.list;
    }

    canEditDirectories() {
        return this.hasDirectoriesNavigation() && this.directoryActions?.edit
    }

    goToDirectory(directory:PgDirectoryData) {
        this.currentDirectoryList = null;
        this.currentDirectory = directory;

        if(this.hasSearchOrTags()) {
            this.filterStatus.search = null;
            this.filterStatus.tags = null;
            this.filterStatus.system_tags = null;

            this.initCurrentDirectory(directory.id).then(() => {
                this.loadCurrentDirectory()
                this.reset()
        
                this._updateDirectoryFragment()
            })
        }
        else {
            this.loadCurrentDirectory()
            this.reset()
    
            this._updateDirectoryFragment()
        }
    }

    private _updateDirectoryFragment() {
        let _fragment = this.currentDirectory?.id || '';
        _fragment = _fragment.toString();

        this.router.navigate([], { fragment: _fragment })
    }

    hasSearchOrTags() {
        return this.getFilterSearch() || this.getFilterTags() || this.getFilterSystemTags();
    }

    getFilterSearch() {
        if(this.filterStatus != null && this.filterStatus.search != null && this.filterStatus.search != '') {
            return this.filterStatus.search
        }
    }

    getFilterTags() {
        if(this.filterStatus != null && this.filterStatus.tags != null && this.filterStatus.tags != '') {
            return this.filterStatus.tags
        }
    }

    getFilterSystemTags() {
        if(this.filterStatus != null && this.filterStatus.system_tags != null && this.filterStatus.system_tags != '') {
            return this.filterStatus.system_tags
        }
    }

    isLoadingDirectoryData = false;

    loadCurrentDirectory() {
        this.setShowCreateDirectory(false);

        this.isLoadingDirectoryData = true;

        let _filter:Array<DataFilter> = null;
        let _search:string = this.getFilterSearch();
        let _tags:string = this.getFilterTags();
        let _system_tags:string = this.getFilterSystemTags();

        if(!this.hasSearchOrTags()) {
            let _id = null;

            if(this.currentDirectory == null)  {
                this.currentDirectoryPath = [];
            }
            else {
                _id = this.currentDirectory.id
    
                let _index = this.currentDirectoryPath.indexOf(this.currentDirectory)
                if(_index != -1) {
                    this.currentDirectoryPath.splice(_index + 1)
                }
                else {
                    this.currentDirectoryPath.push(this.currentDirectory)
                }
            }

            _filter = [{
                field: 'parent_id',
                operator: '==',
                value: [ _id ]
            }]
        }

        this.dataService.getResourceData('Directory', {
            limit: 1000,
            search: _search,
            tags: _tags,
            system_tags: _system_tags,
            filter: _filter
        }).subscribe((data) => {
            this.isLoadingDirectoryData = false;
            this.currentDirectoryList = data;
        })
    }

    createDirectoryLabel:string = null;

    @ViewChild('createDirectoryInput') createDirectoryInput:ElementRef

    showCreateDirectory = false;

    private _setShowCreateDirectoryTimeout = null

    setShowCreateDirectory(val:boolean, delay?:boolean) {
        clearTimeout(this._setShowCreateDirectoryTimeout);

        if(delay) {
            this._setShowCreateDirectoryTimeout = setTimeout(() => {
                this.setShowCreateDirectory(val)
            }, 2500)
        }
        else {
            this.showCreateDirectory = val;

            if(this.showCreateDirectory) {
                this.createDirectoryLabel = null
        
                this._setShowCreateDirectoryTimeout = setTimeout(() => {
                    if(this.createDirectoryInput != null) {
                        let _input = this.createDirectoryInput.nativeElement as HTMLInputElement;
                        _input.focus()
                        _input.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }, 250)
            }
        }
    }

    canCreateDirectory() {
        if(this.createDirectoryLabel == null || this.createDirectoryLabel == '')  return false;
        else {
            for(let _directory of this.currentDirectoryList) {
                if(_directory.label == this.createDirectoryLabel) return false
            }

            return true;
        }
    }

    createDirectory() {
        if(this.canCreateDirectory()) {
            let _parentId = this.currentDirectory?.id;

            this.dataService.postResourceData('Directory', { parent_id: _parentId, realm_id: this.authService.user.realm?.id, label: this.createDirectoryLabel }).subscribe((data) => {
                if(data != null) {
                    this.currentDirectoryList.unshift(data)
                    this.setShowCreateDirectory(false);
                    this.createDirectoryLabel = null;
                    this.loadCurrentDirectory()
                }
            })
        }
    }

    deleteDirectory(directory:PgDirectoryData) {
        this.dataService.deleteElement('Directory', directory.id).subscribe(() => {
            this.loadCurrentDirectory()
        })
    }

    currentRenameDitectory:PgDirectoryData = null;
    currentRenameDitectoryLabel:string = null;

    renameDirectory(directory:PgDirectoryData) {
        this.currentRenameDitectory = directory;
        this.currentRenameDitectoryLabel = this.currentRenameDitectory.label
    }

    canCurrentRenameDirectory() {
        if(this.currentRenameDitectoryLabel == null || this.currentRenameDitectoryLabel == '')  return false;
        else {
            for(let _directory of this.currentDirectoryList) {
                if(_directory.label == this.currentRenameDitectoryLabel) return false
            }

            return true;
        }
    }

    checkDoCurrentRenameDirectory() {
        if(this.canCurrentRenameDirectory()) {
            this.currentRenameDitectory.label = this.currentRenameDitectoryLabel;
            this.dataService.putElementData('Directory', this.currentRenameDitectory.id, { label: this.currentRenameDitectoryLabel }).subscribe(() => {}, () => {})
        }

        this.currentRenameDitectory = null;
    }

    cutResource:'Directory'|'File' = null;
    cutElement:PgDirectoryData|PgFileData = null;

    cutDirectory(directory:PgDirectoryData) {
        this.cutResource = 'Directory'
        this.cutElement = directory;
    }

    isCutDirectory(directory:PgDirectoryData) {
        return this.cutResource == 'Directory' && this.cutElement.id == directory.id;
    }

    cutFile(file:PgFileData) {
        this.cutResource = 'File'
        this.cutElement = file;
    }

    isCutFile(file:PgFileData) {
        return this.cutResource == 'File' && this.cutElement.id == file.id;
    }

    canPasteIntoDirectory() {
        if(this.cutResource == null || this.cutElement == null) return false;
        else {
            if(this.cutResource == 'Directory') {
                let _cutDir = (this.cutElement as PgDirectoryData)

                if(_cutDir.parent_id == this.currentDirectory?.id) {
                    return false;
                }
                else {
                    if(this.currentDirectory == null) return true;
                    else if(this.currentDirectory.path == null) return true; // NB: questo è solo per retrocompatibilità
                    else {
                        let _pathSplit = this.currentDirectory.path.split('/')
                        return _pathSplit.indexOf(_cutDir.id.toString()) == -1
                    }
                }
            }
            else if(this.cutResource == 'File') {
                let _cutFile = this.cutElement as PgFileData;

                return _cutFile.directory_id != this.currentDirectory?.id;
            }
        }
    }

    pasteIntoDirectory() {
        if(this.canPasteIntoDirectory()) {
            if(this.cutResource == 'Directory') {
                this.dataService.putElementData('Directory', this.cutElement.id, { parent_id: this.currentDirectory.id }).subscribe(() => {
                    this.cutResource = null;
                    this.cutElement = null;
                    this.loadCurrentDirectory()
                })
            }
            else if(this.cutResource == 'File') {
                this.dataService.putElementData('File', this.cutElement.id, { directory_id: this.currentDirectory.id }).subscribe(() => {
                    this.cutResource = null;
                    this.cutElement = null;
                    this.reset()
                })
            }
        }
    }

    downloadFile(file:PgFileData) {
        let _a = document.createElement('a');
        _a.href = file.url;
        _a.target = '_blank';
        _a.download = file.nome;
        _a.click();
    }

    getSystemTagsLabel(systemTags:string) {
        let _parsed = PGUtilities.tryParseJSON(systemTags)
        if(_parsed != null && _parsed.length > 0) {
            let _retVal = ''

            for(let _item of _parsed) {
                if(_retVal != '') _retVal += ', '
                _retVal += this.localizationService.translate('OPTIONMAPS.*.system_tags.' + _item)
            }

            return _retVal;
        }
    }

    directoryPropagateChanges = false;

    isLoadingDirectoryDataProgress:number = null;

    async checkDirectoryPropagateChanges(data:any) {
        if(this.directoryPropagateChanges) {
            this.isLoadingDirectoryData = true;

            this.isLoadingDirectoryDataProgress = 0;

            let _checkList:Array<string> = [data.data.id]

            let _directoryList:Array<string> = []
            let _fileList:Array<string> = []

            while(_checkList.length > 0) {
                await new Promise<void>((resolve, reject) => {
                    this.dataService.getResourceData('File', { filter: [
                        { field: 'directory_id', operator: 'in', value: _checkList }
                    ] }).subscribe((data) => {
                        for(let _item of data) {
                            _fileList.push(_item.id)
                        }
                 
                        resolve()
                    })
                })

                await new Promise<void>((resolve, reject) => {
                    this.dataService.getResourceData('Directory', { filter: [
                        { field: 'parent_id', operator: 'in', value: _checkList }
                    ] }).subscribe((data) => {
                        _checkList = []

                        for(let _item of data) {
                            _checkList.push(_item.id)
                            _directoryList.push(_item.id)
                        }

                        resolve()
                    })
                })
            }

            this.isLoadingDirectoryDataProgress = 0.1;

            let _requests = 0;

            for(let _file of _fileList) {
                await new Promise<Array<any>>((resolve, reject) => {
                    this.dataService.putElementData('File', _file, data.changes).subscribe((data) => {
                        _requests++;
                        this.isLoadingDirectoryDataProgress = 0.1 + 0.9 * _requests / (_fileList.length + _directoryList.length);

                        resolve(data)
                    })
                })
            }


            for(let _directory of _directoryList) {
                await new Promise<Array<any>>((resolve, reject) => {
                    this.dataService.putElementData('Directory', _directory, data.changes).subscribe((data) => {
                        _requests++;
                        this.isLoadingDirectoryDataProgress = 0.1 + 0.9 * _requests / (_fileList.length + _directoryList.length);

                        resolve(data)
                    })
                })
            }

            this.isLoadingDirectoryDataProgress = 1;

            setTimeout(() => {
                this.isLoadingDirectoryDataProgress = null;

                this.loadCurrentDirectory()
            }, 500)
        }
        else {
            this.loadCurrentDirectory()
        }
    }
}
