import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { ConfigResource } from "../models/config.resources.model";
import { ConfigProfile } from "../models/config.profiles.model";
import { LocalizationService } from "./localization.service";
import { DataService } from "./data.service";

export class ConfigDefaultsMismatch {
    resource: string
    fields: Array<string>
}

export class ConfigDefaultsChangedResourcePermissionRole {
    name:string

    ignore_user_group: {
        default: boolean;
        changed: boolean;
    }

    actions:Array<{ 
        action:string, 
        default:boolean, 
        changed:boolean 
    }>

    fields: Array<{ 
        name:string, 
        prop:string, 
        default:boolean, 
        changed:boolean 
    }>
}

export class ConfigDefaultsChangedResourcePermission { 
    resource: string 
    roles:Array<ConfigDefaultsChangedResourcePermissionRole> 
}

@Injectable({
    providedIn: 'root'
})
export class ConfigDefaultsService {
    constructor(private configService:ConfigService, private localizationService:LocalizationService, private dataService:DataService) {}

    checkMissingConfigurations() {
        try {
            let _missingResourceConfiguration:Array<string> = [];
            let _foundResourceConfiguration:Array<string> = [];

            for(let i in this._defaultConfigurations) {
                if(this.configService.resources.byModel[i] == null || this.configService.resources.byModel[i].id == null) {
                    _missingResourceConfiguration.push(i)
                }
                else {
                    _foundResourceConfiguration.push(i);
                }
            }

            let _missingAdminRole:Array<string> = [];

            let _missingFieldsConfiguration:Array<ConfigDefaultsMismatch> = [];
            let _missingFieldsPermissions:Array<ConfigDefaultsMismatch> = [];
            let _changedResourcePermissions:Array<ConfigDefaultsChangedResourcePermission> = [];

            let _removedFields:Array<ConfigDefaultsMismatch> = [];

            if(_foundResourceConfiguration.length > 0) {
                for(let _checkResource of _foundResourceConfiguration) {

                    if(this._noAdminResources.indexOf(_checkResource) != -1) {
                        if(this.configService.resources.byModel[_checkResource].types.user_id != null || this.configService.resources.byModel[_checkResource].types.group_id ) {
                            let _hasAdminRole = false;
    
                            for(let i in this.configService.profiles.byModel[_checkResource].roles) {
                                if(this.configService.profiles.byModel[_checkResource].roles[i].ignore_user_group) {
                                    _hasAdminRole = true;
                                    break;
                                }
                            }
    
                            if(!_hasAdminRole) {
                                _missingAdminRole.push(_checkResource)
                            }
                        }
                    }

                    let _missingConfiguration:ConfigDefaultsMismatch = null;
                    let _missingPermissions:ConfigDefaultsMismatch = null;

                    let _removedConfiguration:ConfigDefaultsMismatch = null;

                    for(let i in this.configService.resources.byModel[_checkResource].types) {
                        let _field = this.configService.resources.byModel[_checkResource].types[i];
                        
                        if(_field.missing) {
                            if(_missingConfiguration == null) {
                                _missingConfiguration = { resource: _checkResource, fields: [] };
                                _missingFieldsConfiguration.push(_missingConfiguration)
                            }
            
                            _missingConfiguration.fields.push(i)
                        }
                        else if(_field.removed) {
                            if(_removedConfiguration == null) {
                                _removedConfiguration = { resource: _checkResource, fields: [] };
                                _removedFields.push(_removedConfiguration)
                            }

                            _removedConfiguration.fields.push(i)
                        }
                    }

                    let _basePermissions = this.configService.profiles.byModel[_checkResource].roles[this.configService.profiles.baseRoleId]

                    for(let i in _basePermissions.fields) {
                        if(_basePermissions.fields[i].missing) {

                            if(_missingPermissions == null) {
                                _missingPermissions = { resource: _checkResource, fields: [] };
                                _missingFieldsPermissions.push(_missingPermissions)
                            }
            
                            _missingPermissions.fields.push(i)
                        }
                        else if(_basePermissions.fields[i].removed) {
                            if(_removedConfiguration == null) {
                                _removedConfiguration = { resource: _checkResource, fields: [] };
                                _removedFields.push(_removedConfiguration)
                            }

                            _removedConfiguration.fields.push(i)
                        }
                    }

                    let _changedPermissions:ConfigDefaultsChangedResourcePermission = null;

                    let _default = this._createDefaultConfigProfile(_checkResource)

                    for(let i in _default.roles) {
                        let _defaultRole = _default.roles[i];
                        let _checkRole = this.configService.profiles.byModel[_checkResource].roles[i]

                        let _roleName = i;

                        for(let _role of this.configService.roles) {
                            if(_role.id == i) {
                                _roleName = _role.name
                            }
                        }
    
                        let _roleDiff:ConfigDefaultsChangedResourcePermissionRole = {
                            name: _roleName,
                            ignore_user_group: null,
                            actions: [],
                            fields: []
                        }

                        let _defaultIgnoreUserGroup = _defaultRole.ignore_user_group ? true : false;
                        let _checkIgnoreUserGroup = _checkRole.ignore_user_group ? true : false;

                        if(_defaultIgnoreUserGroup != _checkIgnoreUserGroup) {
                            _roleDiff.ignore_user_group = {
                                default: _defaultIgnoreUserGroup,
                                changed: _checkIgnoreUserGroup
                            }
                        }
    
                        let _hasSomething = false;
    
                        for(let j in _defaultRole.actions) {
                            if(_defaultRole.actions[j] != _checkRole.actions[j]) {
                                _roleDiff.actions.push({ action: j, default: _defaultRole.actions[j], changed: _checkRole.actions[j] })
                                _hasSomething = true;
                            }
                        }
    
                        for(let j in _defaultRole.fields) {
                            for(let k of ['view','edit']) {
                                let _defaultValue = _defaultRole.fields[j][k]
                                let _checkValue =  _checkRole.fields[j][k]

                                if(_defaultValue != _checkValue) {
                                    _roleDiff.fields.push({ 
                                        name: j, 
                                        prop: k, 
                                        default: _defaultValue, 
                                        changed: _checkValue 
                                    })
                                    
                                    _hasSomething = true;
                                }
                            }
                        }
    
                        if(_hasSomething) {
                            if(_changedPermissions == null) {
                                _changedPermissions = {
                                    resource: _checkResource,
                                    roles: []
                                }
                            }
    
                            _changedPermissions.roles.push(_roleDiff)
                        }
                    }
    
                    if(_changedPermissions != null) {
                        _changedResourcePermissions.push(_changedPermissions)
                    }
                }
            }

            return {
                foundResourceConfiguration: _foundResourceConfiguration,
                missingAdminRole: _missingAdminRole,
                missingResourceConfiguration: _missingResourceConfiguration,
                missingFieldsConfiguration: _missingFieldsConfiguration,
                missingFieldsPermissions: _missingFieldsPermissions,
                removedFields: _removedFields,
                changedResourcePermissions: _changedResourcePermissions,
            }
        }
        catch(ex) {
            console.error(ex)
        }
    }

    private _noAdminResources = ['notification']

    private _defaultConfigurationEFResource(config:ConfigResource) {
        config.general.category = 'DMS'

        let _nameOrTitle = 'name'
        if(config.types['name'] == null) {
            _nameOrTitle = 'title'
        }

        config.general.roles.select = '{{id}} - {{' + _nameOrTitle + '}}'
        config.general.roles.title = '{{id}} - {{' + _nameOrTitle + '}}'
        config.general.roles.abstract = '';
        config.general.roles.description = '{{description}}'

        if(config.types.coordinates != null) config.types.coordinates.type = 'location'
        if(config.types.description != null) config.types.description.type = 'html'

        if(config.types.videos != null) {
            config.types.videos.type = 'file'
            config.types.videos.fileType = 'videos'
            config.types.videos.multi = true;
        }

        if(config.types.cover != null) {
            config.types.cover.type = 'file'
            config.types.cover.fileType = 'images'
            config.general.roles.image = '{{cover}}'
        }

        for(let i in config.types) {
            if(/^images/.test(i)) {
                config.types[i].type = 'file'
                config.types[i].fileType = 'images'
                config.types[i].multi = true;
            }
        }

        let _columns = [
            {
                field: "id"
            },
            {
                field: _nameOrTitle
            },
            {
                field: "company_name"
            },
            {
                field: "country"
            },
            {
                field: "region"
            }
        ]

        for(let i = _columns.length - 1; i >= 0; i--) {
            if(_columns[i].field != null) {
                if(config.types[_columns[i].field] == null) {
                    _columns.splice(i, 1);
                }
            }
        }
        
        config.view = {
            name: "Table",
            type: "Table",
            resource: config.model,
            config: {
                "columns": _columns
            }
        }

        return config;
    }

    private _defaultConfigurationEFProfile(config:ConfigProfile) {
        for(let _role of this.configService.roles) {
            if(_role.name == 'base') {
                for(let i in config.roles[_role.id].actions) {
                    config.roles[_role.id].actions[i] = true;
                }

                for(let i in config.roles[_role.id].fields) {
                    config.roles[_role.id].fields[i].view = true;

                    if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at' && i != 'imported') {
                        if(i != 'published' && i != 'user_id' && i != 'group_id') {
                            config.roles[_role.id].fields[i].edit = true;
                        }
                        else {
                            config.roles[_role.id].fields[i].edit = false;
                        }
                    }
                }
            }
            else if(_role.name == 'admin') {
                config.roles[_role.id].ignore_user_group = true;
                
                for(let i in config.roles[_role.id].fields) {
                    if(i == 'published' || i == 'user_id' || i == 'group_id') {
                        config.roles[_role.id].fields[i].view = true;
                        config.roles[_role.id].fields[i].edit = true;
                    }
                }
            }
        }

        return config;
    }

    private _defaultConfigurations: { 
        [resource:string]: {
            resource:(config:ConfigResource) => ConfigResource,
            profile:(config:ConfigProfile) => ConfigProfile,
        }
    } = {
        'User': {
            resource: (config:ConfigResource) => {
                config.general.roles.title = '{{id}} - {{name}} {{surname}}'
                config.general.roles.select = '{{id}} - {{name}} {{surname}}'
                config.general.roles.abstract = '{{email}} {{telephone}}'
                config.general.roles.description = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "User",
                    config: {
                        "columns": [{
                            "field": "id",
                            "sum": null
                        }, {
                            "name": "Name Surname",
                            "roleview": "{{name}} {{surname}}"
                        }, {
                            "field": "email",
                            "sum": null
                        }, {
                            "field": "telephone",
                            "sum": null
                        }, {
                            "field": "system",
                            "sum": null
                        }, {
                            "field": "superadmin",
                            "sum": null
                        }]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        config.roles[_role.id].actions.list = false
                        config.roles[_role.id].actions.create = false

                        config.roles[_role.id].actions.view = true
                        config.roles[_role.id].actions.edit = true
                        config.roles[_role.id].actions.delete = true

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;

                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at' && i != 'system' && i != 'superadmin') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                            else {
                                config.roles[_role.id].fields[i].edit = false; 
                            }
                        }
                    }
                    else if(_role.name == 'admin') {
                        // REALMS: gli admin non gestiscono più gli utenti

                        //config.roles[_role.id].ignore_user_group = true;

                        //for(let i in config.roles[_role.id].actions) {
                        //    config.roles[_role.id].actions[i] = true;
                        //}
                    }
                    else if(_role.name == 'socket') {
                        config.roles[_role.id].ignore_user_group = true;

                        config.roles[_role.id].actions.list = true;
                        config.roles[_role.id].actions.view = true;
                    }
                }

                return config;
            }
        },
        'Role': {
            resource: (config:ConfigResource) => {
                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        },
        'RoleUser': {
            resource: (config:ConfigResource) => {
                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        },
        'Profile': {
            resource: (config:ConfigResource) => {
                config.general.roles.title = '{{id}} - {{user_id}}'
                config.general.roles.select = '{{id}} - {{user_id}}'
                config.general.roles.abstract = ''
                config.general.roles.description = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "User",
                    config: {
                        "columns": [{
                            "field": "id",
                            "sum": null
                        }, {
                            "field": "user_id",
                            "sum": null
                        }]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {

                    let _readonly = ['id','created_at','updated_at','deleted_at']
                    let _baseHidden = ['customer_care','customer_care_languages']

                    if(_role.name == 'base') {
                        config.roles[_role.id].actions.list = false
                        config.roles[_role.id].actions.create = false

                        config.roles[_role.id].actions.view = true
                        config.roles[_role.id].actions.edit = true
                        config.roles[_role.id].actions.delete = true

                        for(let i in config.roles[_role.id].fields) {
                            if(_baseHidden.indexOf(i) == -1) {
                                config.roles[_role.id].fields[i].view = true;

                                if(_readonly.indexOf(i) == -1) {
                                    config.roles[_role.id].fields[i].edit = true;
                                }
                                else {
                                    config.roles[_role.id].fields[i].edit = false; 
                                }
                            }
                            else {
                                config.roles[_role.id].fields[i].view = false;
                                config.roles[_role.id].fields[i].edit = false;
                            }
                        }
                    }
                    else if(_role.name == 'admin') {
                        config.roles[_role.id].ignore_user_group = true;

                        config.roles[_role.id].actions.list = true
                        config.roles[_role.id].actions.create = true

                        for(let i in config.roles[_role.id].fields) {
                            if(_baseHidden.indexOf(i) != -1) {
                                config.roles[_role.id].fields[i].view = true;
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                    else if(_role.name == 'socket') {
                        config.roles[_role.id].ignore_user_group = true;

                        config.roles[_role.id].actions.list = true
                    }
                }

                return config;
            }
        },
        'SystemTag': {
            resource: (config:ConfigResource) => {
                config.general.roles.title = '{{id}} {{label}}'
                config.general.roles.abstract = '{{id}} {{label}}'
                config.general.roles.description = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Group",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "label"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;
                            config.roles[_role.id].fields[i].edit = false;
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at') {
                                config.roles[_role.id].fields[i].view = null;
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                }

                return config;
            }
        },
        'Group': {
            resource: (config:ConfigResource) => {
                config.general.roles.title = '{{id}} {{label}}'
                config.general.roles.abstract = '{{id}} {{label}}'
                config.general.roles.description = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Group",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "label"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;
                            config.roles[_role.id].fields[i].edit = false;
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at') {
                                config.roles[_role.id].fields[i].view = null;
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                }

                return config;
            }
        },
        'UserGroup': {
            resource: (config:ConfigResource) => {
                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;
                            
                            if(i != 'id' && i != 'created_at' && i != 'updated_at') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                    else if(_role.name == 'admin') {
                        config.roles[_role.id].ignore_user_group = true;

                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        },
        'UserRealm': {
            resource: (config:ConfigResource) => {
                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;
                            
                            if(i != 'id' && i != 'created_at' && i != 'updated_at') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                    else if(_role.name == 'admin') {
                        config.roles[_role.id].ignore_user_group = true;

                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        },
        'Content': {
            resource: (config:ConfigResource) => {

                let _columnsConfig = {
                    "columns": [
                        {
                            field: "id"
                        },
                        {
                            field: "tid"
                        }
                    ]
                }

                for(let _language of this.localizationService.availableApplicationLanguages) {
                    for(let _field of this.configService.models[config.model]) {
                        if(_field.name == _language) {
                            _columnsConfig.columns.push({
                                field: _field.name
                            })
                        }
                    }
                }

                config.general.roles.title = '{{tid}}'
                config.general.roles.abstract = '{{' + _columnsConfig.columns[2].field + '}} {{' + _columnsConfig.columns[3].field + '}}'
                config.general.roles.description = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: _columnsConfig
                }

                config.types.tid.required = true;

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;
                            config.roles[_role.id].fields[i].edit = false;
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at') {
                                config.roles[_role.id].fields[i].view = null;
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                }

                return config;
            }
        },
        'File': {
            resource: (config:ConfigResource) => {
                config.general.roles.abstract = '{{nome_file}} {{category}}'
                config.general.roles.description = '{{url}}'

                config.view = {
                    name: "Files",
                    type: "Files",
                    resource: "File",
                    config: null
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;

                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                            else {
                                config.roles[_role.id].fields[i].edit = false;
                            }
                        }
                    }
                }

                return config;
            }
        }, 
        'Directory': {
            resource: (config:ConfigResource) => {
                config.general.roles.select = '{{id}} - {{label}}'
                config.general.roles.title = '{{id}} - {{label}}'
                config.general.roles.abstract = '{{path}}'
                config.general.roles.description = '{{path}}'

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        config.roles[_role.id].ignore_user_group = true;

                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;

                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                            else {
                                config.roles[_role.id].fields[i].edit = false;
                            }
                        }
                    }
                }

                return config;
            }
        }, 
        'Experience': {
            resource: (config:ConfigResource) => {
                config.general.category = 'DMS'

                config.general.roles.slug = '{{title}}'
                config.general.roles.select = '{{id}} - {{title}}'
                config.general.roles.title = '{{title}}'
                config.general.roles.description = '{{description}}'
                
                config.types.coordinates.type = 'location'
                config.types.description.type = 'html'
                config.types.cost_range.type = 'rating'
                config.types.minimum_guaranteed.type = 'slider'
                config.types.minimum_guaranteed.min = 1
                config.types.minimum_guaranteed.max = 20
                config.types.broker_discount.min = 0
                config.types.broker_discount.max = 100
                config.types.cancellation_deadline.type = 'slider'
                config.types.cancellation_deadline.min = 1
                config.types.cancellation_deadline.max = 60
                config.types.tickets.type = 'tickets'
                config.types.payment.multi = true;
                
                config.types.videos.type = 'file'
                config.types.videos.fileType = 'videos'
                config.types.videos.multi = true;
                config.types.images.type = 'file'
                config.types.images.fileType = 'images'
                config.types.images.multi = true;

                config.types.certificate.type = 'boolean'
                config.types.template.type = 'boolean'
                config.types.published.type = 'boolean'

                config.types.languages.multi = true

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Experience",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "title"
                            },
                            {
                                field: "category"
                            },
                            {
                                field: "tags"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;

                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at' && i != 'imported') {
                                if(i != 'published' && i != 'user_id' && i != 'group_id') {
                                    config.roles[_role.id].fields[i].edit = true;
                                }
                                else {
                                    config.roles[_role.id].fields[i].edit = false;
                                }
                            }
                            else {
                                config.roles[_role.id].fields[i].edit = false;
                            }
                        }
                    }
                    else if(_role.name == 'admin') {
                        config.roles[_role.id].ignore_user_group = true;

                        for(let i in config.roles[_role.id].fields) {
                            if(i == 'published' || i == 'user_id' || i == 'group_id') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                }

                return config;
            }
        }, 
        'Host': {
            resource: (config:ConfigResource) => {
                config = this._defaultConfigurationEFResource(config)

                config.types.stars.type = 'rating'
                
                return config;
            },
            profile: (config:ConfigProfile) => {
                config = this._defaultConfigurationEFProfile(config)
                return config
            }
        }, 
        'Eatery': {
            resource: (config:ConfigResource) => {
                config = this._defaultConfigurationEFResource(config)
                return config;
            },
            profile: (config:ConfigProfile) => {
                config = this._defaultConfigurationEFProfile(config)
                return config
            }
        }, 
        'ExperienceSupplier': {
            resource: (config:ConfigResource) => {
                config = this._defaultConfigurationEFResource(config)
                return config;
            },
            profile: (config:ConfigProfile) => {
                config = this._defaultConfigurationEFProfile(config)
                return config
            }
        }, 
        'Poi': {
            resource: (config:ConfigResource) => {
                config = this._defaultConfigurationEFResource(config)

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Poi",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "name"
                            },
                            {
                                field: "category"
                            },
                            {
                                field: "country"
                            },
                            {
                                field: "region"
                            },
                            {
                                field: "province"
                            }
                        ]
                    }
                }

                return config;
            },
            profile: (config:ConfigProfile) => {
                config = this._defaultConfigurationEFProfile(config)
                return config
            }
        }, 
        'Itinerary': {
            resource: (config:ConfigResource) => {
                config = this._defaultConfigurationEFResource(config)
                return config;
            },
            profile: (config:ConfigProfile) => {
                config = this._defaultConfigurationEFProfile(config)
                return config
            }
        }, 
        'Article': {
            resource: (config:ConfigResource) => {
                config = this._defaultConfigurationEFResource(config)

                config.types.tags.type = 'string'
                config.types.category.type = 'select'
                config.types.coordinates.type = 'location'
                config.types.text.type = 'html'
                config.types.language.type = 'select'
                config.types.priority.type = 'select'

                return config;
            },
            profile: (config:ConfigProfile) => {
                config = this._defaultConfigurationEFProfile(config)
                return config
            }
        }, 
        'TourProduct': {
            resource: (config:ConfigResource) => {
                config.general.roles.select = '{{id}} - {{name}}'
                config.general.roles.title = '{{name}}'
                config.general.roles.abstract = '{{description}}'
                config.general.roles.description = '';
                config.general.roles.image = '{{{content.image}}}'
                config.general.roles.location = '{{content.location}}'
                config.general.roles.geoloc = '{{coordinates}}'
                config.general.roles.price = '{{price}}'

                config.view = {
                    name: "Cards",
                    type: "Cards",
                    resource: "TourProduct",
                    config: null
                }

                config.types['content'].type = 'json'

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            if(i == 'content') {
                                config.roles[_role.id].fields[i].view = false;
                                config.roles[_role.id].fields[i].edit = false;
                            }
                            else {
                                config.roles[_role.id].fields[i].view = true;

                                if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at') {
                                    config.roles[_role.id].fields[i].edit = true;
                                }
                                else {
                                    config.roles[_role.id].fields[i].edit = false;
                                }
                            }
                        }
                    }
                    else if(_role.name == 'admin' || _role.name == 'socket') {
                        config.roles[_role.id].ignore_user_group = true;
                        config.roles[_role.id].fields['content'].edit = true;
                        config.roles[_role.id].fields['content'].view = true;
                    }
                }

                return config;
            }
        }, 
        'Booking': {
            resource: (config:ConfigResource) => {
                config.general.category = 'DMS'

                config.general.roles.select = '{{id}} - {{pid}}'
                config.general.roles.title = '{{id}} - {{pid}}'
                config.general.roles.abstract = ''
                config.general.roles.description = '';
                config.general.roles.image = ''
                config.general.roles.location = ''
                config.general.roles.geoloc = ''
                config.general.roles.price = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "pid"
                            },
                            {
                                field: "experience_id"
                            },
                            {
                                field: "date"
                            },
                            {
                                field: "user_email"
                            },
                            {
                                field: "user_phone"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        config.roles[_role.id].actions.list = true;
                        config.roles[_role.id].actions.view = true;
                        config.roles[_role.id].actions.edit = false;
                        config.roles[_role.id].actions.create = true;
                        config.roles[_role.id].actions.delete = false;

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;

                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at' && i != 'status') {
                                config.roles[_role.id].fields[i].edit = i != 'user_id';
                            }
                            else {
                                config.roles[_role.id].fields[i].edit = false
                            }
                        }
                    }
                    else if(_role.name == 'admin') {
                        config.roles[_role.id].actions.edit = true;
                        config.roles[_role.id].actions.delete = true;

                        config.roles[_role.id].ignore_user_group = true;

                        for(let i in config.roles[_role.id].fields) {
                            if(i == 'user_id') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                }

                return config;
            }
        }, 
        'AccessToken': {
            resource: (config:ConfigResource) => {
                config.general.category = 'DMS'

                config.general.roles.select = '{{id}} - {{resource}} {{resource_id}}'
                config.general.roles.title = '{{id}} - {{resource}} {{resource_id}}'
                config.general.roles.abstract = '{{activation_time}}'
                config.general.roles.description = '';
                config.general.roles.image = ''
                config.general.roles.location = ''
                config.general.roles.geoloc = ''
                config.general.roles.price = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "resource"
                            },
                            {
                                field: "resource_id"
                            },
                            {
                                field: "activation_time"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;
                            config.roles[_role.id].fields[i].edit = false
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at') {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                        }
                    }
                    else if(_role.name == 'socket') {
                        config.roles[_role.id].actions.list = true;
                        config.roles[_role.id].actions.view = true;
                        config.roles[_role.id].actions.edit = true;
                        config.roles[_role.id].fields['activation_time'].edit = true;
                    }
                }

                return config;
            }
        },
        'ChatSession': {
            resource: (config:ConfigResource) => {
                config.general.category = 'Chat'

                config.general.roles.select = '{{id}} - {{username}}'
                config.general.roles.title = '{{id}} - {{username}}'
                config.general.roles.abstract = ''
                config.general.roles.description = '';
                config.general.roles.image = ''
                config.general.roles.location = ''
                config.general.roles.geoloc = ''
                config.general.roles.price = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "username"
                            },
                            {
                                field: "session_id"
                            },
                            {
                                field: "created_at"
                            },
                            {
                                field: "closed_at"
                            },
                            {
                                field: "message_count"
                            },
                            {
                                field: "pid"
                            },
                            {
                                field: "data"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }
                    }
                    else if(_role.name == 'socket') {
                        config.roles[_role.id].actions.list = true;
                        config.roles[_role.id].actions.view = true;
                        config.roles[_role.id].actions.edit = true;
                        config.roles[_role.id].actions.create = true;
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        }, 
        'ChatMessage': {
            resource: (config:ConfigResource) => {
                config.general.category = 'Chat'

                config.general.roles.select = '{{id}} - {{from}} {{to}}'
                config.general.roles.title = '{{id}} - {{from}} {{to}}'
                config.general.roles.abstract = ''
                config.general.roles.description = '';
                config.general.roles.image = ''
                config.general.roles.location = ''
                config.general.roles.geoloc = ''
                config.general.roles.price = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "from"
                            },
                            {
                                field: "to"
                            },
                            {
                                field: "content"
                            },
                            {
                                field: "data"
                            },
                            {
                                field: "created_at"
                            },
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }
                    }
                    else if(_role.name == 'socket') {
                        config.roles[_role.id].actions.list = true;
                        config.roles[_role.id].actions.view = true;
                        config.roles[_role.id].actions.edit = true;
                        config.roles[_role.id].actions.create = true;
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        },
        'CallLog': {
            resource: (config:ConfigResource) => {
                config.general.category = 'Chat'
                
                config.general.roles.select = '{{id}} - {{channel}} {{username}}'
                config.general.roles.title = '{{id}} - {{channel}} {{username}}'
                config.general.roles.abstract = ''
                config.general.roles.description = '';
                config.general.roles.image = ''
                config.general.roles.location = ''
                config.general.roles.geoloc = ''
                config.general.roles.price = ''

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "channel"
                            },
                            {
                                field: "username"
                            },
                            {
                                field: "call_id"
                            },
                            {
                                field: "in"
                            },
                            {
                                field: "out"
                            },
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }
                    }
                    else if(_role.name == 'socket') {
                        config.roles[_role.id].actions.list = true;
                        config.roles[_role.id].actions.view = true;
                        config.roles[_role.id].actions.edit = true;
                        config.roles[_role.id].actions.create = true;
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        }, 
        'UserPlan': {
            resource: (config:ConfigResource) => {
                config.types['language'].type = 'select'

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "user_id"
                            },
                            {
                                field: "plan_id"
                            },
                            {
                                field: "acceptance_date"
                            },
                            {
                                field: "pid"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                let _readonly = ['id','created_at','updated_at','deleted_at','acceptance_date','user_IP','status','pid']

                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;

                            if(_readonly.indexOf(i) == -1) {
                                config.roles[_role.id].fields[i].edit = true;
                            }
                            else {
                                config.roles[_role.id].fields[i].edit = false; 
                            }
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }

                        config.roles[_role.id].ignore_user_group = true;
                    }
                }

                return config;
            }
        },
        'Notification': {
            resource: (config:ConfigResource) => {
                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }

                        config.roles[_role.id].actions.list = true;
                        config.roles[_role.id].actions.view = true;
                        config.roles[_role.id].actions.edit = true;

                        for(let i in config.roles[_role.id].fields) {
                            config.roles[_role.id].fields[i].view = true;
                            config.roles[_role.id].fields[i].edit = i == 'status';
                        }
                    }
                }

                return config;
            }
        },
        'TotemEnvironment': {
            resource: (config:ConfigResource) => {
                config.general.category = 'Totem'

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Content",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "pid"
                            },
                            {
                                field: "configuration"
                            },
                            {
                                field: "data"
                            }
                        ]
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }
                    }
                    else if(_role.name == 'admin') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        },
        'Faq': {
            resource: (config:ConfigResource) => {
                config.general.category = 'DMS'

                config.view = {
                    name: "Table",
                    type: "Table",
                    resource: "Faq",
                    config: {
                        "columns": [
                            {
                                field: "id"
                            },
                            {
                                field: "title"
                            },
                            {
                                field: "destination"
                            },
                            {
                                field: "content"
                            }
                        ]
                    }
                }

                config.types.title.required = true;
                config.types.content.required = true;

                let _fieldsHidden = ['user_id']
                let _fieldsFullWidth = ['content']

                let _fieldList = config.form.layout.groups[0].fields

                for(let i = _fieldList.length - 1; i >= 0; i--) {
                    let _field = _fieldList[i];

                    if(_fieldsHidden.indexOf(_field.name) != -1) {
                        _field.hidden = true;
                    }
                    else {
                        if(_fieldsFullWidth.indexOf(_field.name) != -1) {
                            _field.display = { fullWidth: true };
                        }
                    }
                }
                
                let _fieldsPushBack = ['group_id','realm_id']

                for(let j = _fieldsPushBack.length - 1; j >= 0; j--) {
                    for(let i = 0; i < _fieldList.length; i++) {
                        let _field = _fieldList[i];

                        if(_field.name == _fieldsPushBack[j]) {
                            _fieldList.splice(i, 1)
                            _fieldList.push(_field)
                            break;
                        }
                    }
                }

                return config
            },
            profile: (config:ConfigProfile) => {
                for(let _role of this.configService.roles) {
                    if(_role.name == 'base') {
                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = false;
                        }
                    }
                    else if(_role.name == 'admin') {
                        config.roles[_role.id].ignore_user_group = true;

                        for(let i in config.roles[_role.id].actions) {
                            config.roles[_role.id].actions[i] = true;
                        }
                    }
                }

                return config;
            }
        },
    }

    private _createDefaultConfigResource(resource:string) {
        return this._defaultConfigurations[resource].resource(new ConfigResource(resource, null, this.configService.models[resource], this.configService.relations.byModel[resource]));
    }

    private _createDefaultConfigProfile(resource:string) {
        // TOFIX: questa doppia creazione dell'oggetto serve perché la default configuration si applica su una configurazione esistente, ma vanno riapplicate le modifiche dal model (ie: campi readonly da modello non modificabili)

        let _retVal = new ConfigProfile(resource, null, this.configService.roles, this.configService.models[resource], this.configService.relations.byModel[resource]);
        _retVal = this._defaultConfigurations[resource].profile(_retVal);

        return new ConfigProfile(resource, _retVal.toData(false), this.configService.roles, this.configService.models[resource], this.configService.relations.byModel[resource])
    }

    createDefaultConfiguration(resource:string) {
        return new Promise<void>((resolve, reject) => {
            if(this._defaultConfigurations[resource] == null) {
                reject()
            }
            else {
                let _configResource = this._createDefaultConfigResource(resource)
                let _configProfile = this._createDefaultConfigProfile(resource)

                //this.dataService.clearCache();
                
                this.dataService.postConfigData(_configResource.toData(true)).subscribe(() => {
                    let _reqCount = 0;

                    for(let i in _configProfile.roles) {
                        _reqCount++;

                        this.dataService.postPermissionData(_configProfile.roles[i].toData(true)).subscribe(() => {
                            _reqCount--;
                            if(_reqCount == 0) {
                                resolve();
                            }
                        }) // gestione errori?
                    }

                    if(_reqCount == 0) {
                        resolve()
                    }
                })
            }
        })
    }
}