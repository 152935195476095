import { Component, Input, OnChanges, EventEmitter, Output, OnInit } from '@angular/core';
import { PgSelectOption } from '../../pg-ui-controls/pg-select/pg-select.component';
import { OptionMapsService } from '../../services/option-maps.service';
import { PgFilterStatus } from '../../services/data.service';


@Component({
  selector: 'app-pg-filter-editor-search',
  templateUrl: './pg-filter-editor-search.component.html',
  styleUrls: ['./pg-filter-editor-search.component.scss']
})
export class PGFilterEditorSearch implements OnInit, OnChanges {
 
    @Input() status:PgFilterStatus
    @Input() appliedFilter:PgFilterStatus

    @Input() hasTags:boolean;
    @Input() hasSystemTags:boolean;

    @Output() applySearch = new EventEmitter<void>();

    systemTagOptions:Array<PgSelectOption> = null;

    constructor(private optionMapsService:OptionMapsService) { }

    ngOnInit() {
        this.systemTagOptions = this.optionMapsService.getFixedOptionMap('*', 'system_tags')
    }

    ngOnChanges(simpleChanges:any) {
    }

    hasAppliedSearch() {
        return this.appliedFilter?.search != null;
    }

    /*onSearchKeyUp(event:KeyboardEvent) {
        if(event.keyCode == 13) {
            this.applySearch.emit()
        }
    }*/

    hasAppliedTags() {
        return this.appliedFilter?.tags != null;
    }
    
    hasAppliedSystemTags() {
        return this.appliedFilter?.system_tags != null;
    }
}
