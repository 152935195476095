import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigResourceTypes } from '../models/config.resources.model';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import moment from 'moment';
import { TimetableData, TimetableDataList } from '../models/timetable.model';
import { TicketsData } from '../models/tickets.model';
import { Observable, of, Subject } from 'rxjs';

import * as Mustache from 'mustache';
import { EnvironmentService } from './environment.service';
import { catchError, map } from 'rxjs/operators';
import { SurveyData } from '../models/survey.model';
import { PersistenceService } from './persistence.service';

export class LocalizationData {

    weekStart:number;
    dayNames:Array<string>;
    monthNames:Array<string>;
    dateFormats: {
        short:string,
        long:string
    };
    timeFormat:string;
    shortDays:string;

    decimalSeparator:string;
    thousandsSeparator:string;

    translations: {
        [id:string]: any
    }
}

export class LocalizationFormat {
    constructor(private service:LocalizationService) {}

    boolean(booleanVal:any):string {
        if(booleanVal) {
            return '<i class="fa-regular fa-check"></i>'
        }
        else {
            return '<i class="fa-regular fa-times"></i>'
        }
    }

    date(dateVal:number|string|Date|{ year: number, month: number, day: number }, longFormat?:boolean, noYear?:boolean):string {
        if(dateVal != null) {
            let _formatName = longFormat ? 'long' : 'short';
            
            if(typeof dateVal == 'object' && !(dateVal instanceof Date)) {
                if(dateVal.year == null) {
                    _formatName += 'MonthDay'
                    dateVal.year = 1970
                }

                dateVal = new Date(dateVal.year, dateVal.month - 1, dateVal.day)
            }

            let _format = this.service.data.dateFormats[_formatName];

            if(noYear) _format = _format.replace(/\s*\/?YYYY/, '');

            let _moment = moment(dateVal);

            _format = _format.replace(/DayN/g, '[' + this.service.data.dayNames[_moment.weekday()] + ']')
            _format = _format.replace(/MonthN/g, '[' + this.service.data.monthNames[_moment.month()] + ']')

            return _moment.format(_format);
        }
    }

    duration(timeVal:number|string, sec?:boolean, ms?:boolean) {
        if(timeVal != null) {
            if(typeof timeVal == 'string') {
                let _timeSplit = timeVal.split(':');

                timeVal = 0;

                for(let i = 0; i < 3; i++) {
                    if(_timeSplit[i] != null) {
                        let _cVal = parseInt(_timeSplit[i]);

                        for(let j = i; j < 2; j++) {
                            _cVal *= 60
                        }

                        timeVal += _cVal * 1000;
                    }
                }
            }
            
            let _days = Math.floor(timeVal / 1000 / 60 / 60 / 24)
            let _hours = Math.floor((timeVal / 1000 / 60 / 60) % 24)
            let _minutes = Math.floor((timeVal / 1000 / 60) % 60)

            let _retVal = ''

            if(_days > 0) {
                _retVal += _days + this.service.data.shortDays;
            }

            if(_hours != 0 || _minutes != 0 || sec || ms) {
                if(_retVal != '') _retVal += ' ';

                let _hoursString = _hours.toString();
                if(_hoursString.length < 2) _hoursString = '0' + _hoursString;

                _retVal += _hoursString;

                let _minutesString = _minutes.toString();
                if(_minutesString.length < 2) _minutesString = '0' + _minutesString;
    
                _retVal += ':' + _minutesString;
            }

            if(sec) {
                if(_retVal != '') _retVal += ':';

                let _seconds = Math.floor((timeVal / 1000) % 60)

                let _secondsString = _seconds.toString();
                if(_secondsString.length < 2) _secondsString = '0' + _secondsString;
    
                _retVal += _secondsString;

                if(ms) {
                    let _ms = Math.floor(timeVal) % 1000;

                    let _msString = _ms.toString();
                    if(_msString.length < 3) _msString = '0' + _msString;
                    if(_msString.length < 3) _msString = '0' + _msString;
        
                    _retVal += '.' + _msString;
                }
            }

            return _retVal;
        }
    }

    time(timeVal:number|string|Date):string {
        if(timeVal != null) {
            let _moment = moment(timeVal);
            if(_moment.isValid()) {
                return _moment.format(this.service.data.timeFormat);
            }
            else if(typeof timeVal == 'string') {
                if(/\d\d:\d\d:\d\d/.test(timeVal)) return moment(timeVal, 'HH:mm:ss').format(this.service.data.timeFormat);
                else if(/\d\d:\d\d/.test(timeVal)) return moment(timeVal, 'HH:mm').format(this.service.data.timeFormat);
            }
        }
    }

    datetime(dateTimeVal:any):string {
        if(dateTimeVal != null) {
            dateTimeVal = moment(dateTimeVal).local().format('YYYY-MM-DD HH:mm:ss');
            return this.date(dateTimeVal) + ' ' + this.time(dateTimeVal);
        }
    }

    distance(val:number) {
        if(val != null) {
            if(val > 1000) {
                return (Math.round(val / 100) / 10).toString() + ' Km'
            }
            else return val + ' m';
        }
    }

    decimal(decimalVal:number):string {
        if(decimalVal == null) return '';
        else {
            if(typeof decimalVal == 'string') {
                decimalVal = parseFloat(decimalVal);
            }

            if(isNaN(decimalVal)) return '';
            else return decimalVal.toFixed(2).replace('.', this.service.data.decimalSeparator);
        }
    }

    money(moneyVal:number):string {
        return this.decimal(moneyVal) + ' €';
    }

    percentage(percVal:number):string {
        return this.decimal(percVal) + '%';
    }

    password():string {
        return '***';
    }

    fileSize(sizeVal:number):string {
        var _units = ['B', 'KB', 'MB', 'GB', 'TB'];

        for(var i = 0; i < _units.length; i++) {
            if(sizeVal < 1024) {
                break;
            }
            else {
                sizeVal /= 1024;
            }
        }

        sizeVal = Math.round(sizeVal * 100) / 100;

        return sizeVal.toString().replace('.', ',') + ' ' + _units[i];
    }

    html(htmlVal:string):string {
        if(htmlVal != null) {
            return '<div class="PGFormatHTML">' + htmlVal + '</div>';
        }
    }

    constraint(operator:string, field:string) {
        return this.service.translate('constraint.' + operator, { field: field });
    }

    rating(value:number) {
        let _retVal = '';

        for(let i = 0; i < 5; i++) {
            if(i < value) {
                _retVal += '<i class="fa-regular fa-star"></i>'
            }
            else {
                _retVal += '<i class="fa-regular fa-star"></i>'
            }
        }

        return _retVal;
    }

    location(value:{ coordinates: Array<number>, type:string }) {
        if(value == null) return '';
        else {
            if(value.type == 'Point') {
                return value.coordinates[0].toFixed(6) + ', ' + value.coordinates[1].toFixed(6);
            }
        }
    }

    timetableDepartures(cVal:any) {
        return this.timetable(cVal, 'departures')
    }

    timetableDates(cVal:any) {
        return this.timetable(cVal, 'dates')
    }

    timetableOpenings(cVal:any) {
        return this.timetable(cVal, 'openings')
    }

    timetableSlots(cVal:any) {
        return this.timetable(cVal, 'slots')
    }

    timetablePeriods(cVal:any) {
        return this.timetable(cVal, 'periods')
    }

    timetable(cVal:any, type:'slots'|'openings'|'periods'|'dates'|'departures', skipSlotDuration?:boolean) {
        if(cVal == null) return '';
        else {
            let _retVal = '';

            let _cObj:TimetableData|Array<TimetableData>|TimetableDataList = cVal;

            if(typeof cVal == 'string') {
                try {
                    _cObj = JSON.parse(cVal);
                }
                catch(ex) {
                    _cObj = null;
                }
            }

            if(_cObj != null) {
                if('toData' in _cObj) {
                    _cObj = _cObj.toData()
                }

                if('length' in _cObj) {
                    if(_cObj.length > 0) {
                        _retVal += '<table class="PGFormatTable PGFormatTable--Timetable">'
                        
                        let _isFirst = true;
        
                        if(type == 'slots' && _cObj[0] != null && _cObj[0].slotDuration != null) {
                            _retVal += '<tr class="PGFormatTable--Timetable-SlotDuration"><td><b>' + this.service.translate('pg-timetable-editor.experience-duration') + '</b> ' + _cObj[0].slotDuration.replace(/^(\d\d:\d\d):\d\d$/, '$1') + '</td></tr>';
                            _isFirst = false;
                        }
        
                        for(let _cItem of _cObj) {
                            if(_isFirst) {
                                _isFirst = false;
                                _retVal += '<tr>';
                            }
                            else {
                                _retVal += '<tr class="pt-2">';
                            }
        
                            _retVal += '<td>' + this.timetable(_cItem, type, true) + '</td>';
        
                            _retVal += '</tr>';
                        }
        
                        _retVal += '</table>'
                    }
                }
                else {
                    if(!skipSlotDuration && _cObj.slotDuration != null) {
                        if(type == 'slots') {
                            _retVal += '<div class="PGFormatTable--Timetable-SlotDuration"><b>' + this.service.translate('pg-timetable-editor.experience-duration') + '</b> ' + _cObj.slotDuration.replace(/^(\d\d:\d\d):\d\d$/, '$1') + '</div>';
                        }
                        else if(type == 'departures') {
                            let _val:number = null;
    
                            let _timeSplit = _cObj.slotDuration.split(':');
                            _val = parseInt(_timeSplit[0]) * 60 + parseInt(_timeSplit[1])
                            _val /= 1440
    
                            _retVal += '<div class="PGFormatTable--Timetable-SlotDuration"><b>' + this.service.translate('pg-timetable-editor.package-duration') + '</b> ' + _val + '</div>';
                        }
                    }
    
                    if(type != 'periods' && type != 'dates') {
                        _retVal += '<div><b>' + this.service.translate('pg-timetable-editor.activity-periods') + '</b></div>';
                    }
                    
                    if(_cObj.days.length == 0) {
                        _retVal += '<div>' + this.service.translate('pg-timetable-editor.all-year') + '</div>';
                    }
                    else {
                        let _cDaysString = '';
        
                        for(let _cDay of _cObj.days) {
                            if(_cDay.begin.month == 1 && _cDay.begin.day == 1 && _cDay.end.month == 12 && _cDay.end.day == 31) {
                                _retVal += '<div>' + this.service.translate('pg-timetable-editor.all-year') + '</div>';
                            }
                            else {
                                let _beginYear = _cDay.year;
                                let _endYear = _cDay.year;

                                if(_beginYear != null) {
                                    if(_cDay.end.month < _cDay.begin.month || (_cDay.end.month == _cDay.begin.month && _cDay.end.day < _cDay.begin.day)) {
                                        _endYear++;
                                    }
                                }

                                _cDaysString += '<div>'
                                _cDaysString += this.service.translate('pg-timetable-editor.from-day') + ' ' + this.date({ year: _beginYear, month: _cDay.begin.month, day: _cDay.begin.day });
                                _cDaysString += ' ' + this.service.translate('pg-timetable-editor.to-day') + ' ' + this.date({ year: _endYear, month: _cDay.end.month, day: _cDay.end.day });
                                _cDaysString += '</div>'
                            }
                        }
        
                        _retVal += _cDaysString
                    }

                    if(_cObj.unavailable) {
                        _retVal += '<div class="text-danger">'
                        this.service.translate('pg-timetable-editor.unavailable-period')
                        _retVal += '</div>'
                    }
                    else {
                        if(type != 'periods' && type != 'dates') {
                            _retVal += '<div><b>' + this.service.translate('pg-timetable-editor.times-day') + '</b></div>';
            
                            let _prevDayString = null;
                
                            for(let _cHour of _cObj.hours) {
                                let _cDaysString = '<div class="d-flex my-1">';
                            
                                for(let i = 0; i < 7; i++) {
                                    let _index = (this.service.data.weekStart + i) % 7;

                                    _cDaysString += '<div class="PGTimetableFormat-Day ' + (_cHour.days[_index] ? 'active' : '') + '">' + this.service.data.dayNames[_index].charAt(0) + '</div>'
                                }
        
                                _cDaysString += '</div>'
            
                                if(_cDaysString != _prevDayString) {
                                    _retVal += _cDaysString;
                                    _prevDayString = _cDaysString;
                                }
        
                                if(_cHour.begin != null && _cHour.end != null) {
                                    let _cHoursString = '<span class="badge bg-primary me-2">' + _cHour.begin;
                                    if(type != 'departures') _cHoursString += ' - ' + _cHour.end;
                                    _cHoursString += '</span>';
                    
                                    _retVal += '<div class="PGTimetableFormat-Hours">' + _cHoursString + '</div>';
                                }
                            }
                        }
                    }
                }
            }

            return _retVal;
        }
    }

    ticketsHost(cVal:any) {
        return this.tickets(cVal, 'host')
    }

    tickets(cVal:any, type?:string) {
        if(cVal == null) return '';
        else {
            let _retVal = '<table class="PGFormatTable">';

            let _cObj:TicketsData = JSON.parse(cVal);

            let _isFirst = true;

            if(_cObj.fullPrice != null) {
                _retVal += '<tr><td>' + this.service.translate('pg-tickets-editor.full-price' + (type == 'host' ? '-single' : '')) + '</td><td class="pl-3 text-end">' + _cObj.fullPrice + ' &euro;</td></tr>';
                _isFirst = false;
            }

            for(let _cOther of _cObj.otherTickets) {
                if(_isFirst) {
                    _isFirst = false;
                    _retVal += '<tr>';
                }
                else {
                    _retVal += '<tr class="border-top">';
                }

                let _namesString = '';

                if(typeof _cOther.name == 'string') {
                    _namesString = _cOther.name;
                    if(_namesString == null) _namesString = '';
                }
                else {
                    for(let _cLanguage in _cOther.name) {
                        let _cName = _cOther.name[_cLanguage];
                        if(_cName == null) _cName = '';
                        
                        if(_namesString != '') _namesString += '<br/>';
                        _namesString += '<span class="badge bg-primary PGLanguageBadge">' + _cLanguage.split('_')[0].toUpperCase() + '</span> ' + _cName;
                    }
                }

                _retVal += '<td>' + _namesString + '</td><td class="pl-3 text-end">' + _cOther.price + ' &euro;</td>'
                _retVal += '</tr>';
            }

            _retVal += '</table>';

            return _retVal
        }
    }

    survey(cVal:any) {
        if(cVal == null) return '';
        else {
            let _retVal = '<table class="PGFormatTable">';

            let _cObj:SurveyData = JSON.parse(cVal);

            let _isFirst = true;

            for(let _cQuestion of _cObj.questions) {
                if(_isFirst) {
                    _isFirst = false;
                    _retVal += '<tr>';
                }
                else {
                    _retVal += '<tr class="border-top">';
                }

                let _namesString = '';

                if(typeof _cQuestion.text == 'string') {
                    _namesString = _cQuestion.text;
                    if(_namesString == null) _namesString = '';
                }
                else {
                    for(let _cLanguage in _cQuestion.text) {
                        let _cName = _cQuestion.text[_cLanguage];
                        if(_cName == null) _cName = '';
                        
                        if(_namesString != '') _namesString += '<br/>';
                        _namesString += '<span class="badge bg-primary PGLanguageBadge">' + _cLanguage.split('_')[0].toUpperCase() + '</span> ' + _cName;
                    }
                }

                _retVal += '<td>' + _namesString + '</td><td class="pl-3 text-end">' + this.service.translate('pg-survey-editor.answer-type-' + _cQuestion.type) + '</td>'
                _retVal += '</tr>';
            }

            _retVal += '</table>';

            return _retVal
        }
    }

    byType(cVal:any, cType?:string):string {
        cType = cType.replace(/-\w/g, (val) => {
            return val.charAt(1).toUpperCase();
        })

        if(cVal == null) return '';
        else {
            if(cType == null) {
                cType = 'string';
            }

            if(this[cType] != null) return this[cType](cVal);
            else {
                return cVal.toString();
            }
        }
    }

    private _internalLanguageHandlingTypes = ['tickets','tickets-host','survey'];

    hasTypeInternalLanguageHandling(type:string) {
        return this._internalLanguageHandlingTypes.indexOf(type) != -1;
    }

    byConfig(cVal:any, cConfig?:ConfigResourceTypes):string {
        if(cConfig == null) {
            return this.byType(cVal);
        }
        else if (cConfig.locale && !this.hasTypeInternalLanguageHandling(cConfig.type)) {
            let _retVal = ''
            
            if(cVal != null) {
                let _cValObj = JSON.parse(cVal)

                if(_cValObj != null) {
                    for(let i in _cValObj) {
                        if(_cValObj[i] != null) {
                            _retVal += '<span class="badge bg-primary PGLanguageBadge">' + i.split('_')[0].toUpperCase() + '</span> ' + this.byType(_cValObj[i], cConfig.type) + '<br/>'
                        }
                    }
                }
            }

            return _retVal;
        }
        else if(cConfig.type == 'file') {
            let _retVal = '';

            if(cVal != null) {
                _retVal += '<div class="PGFileFormat">';

                let _checkVal = cVal;

                if(cConfig.multi) {
                    _checkVal = JSON.parse(cVal);
                }
                else {
                    _checkVal = [_checkVal];
                }

                for(let _val of _checkVal) {
                    if(_retVal != null) _retVal += ' '

                    switch(cConfig.fileType) {
                        case 'images': _retVal += '<div><img src="' + _val + '"/></div>'; break;
                        case 'videos': _retVal += '<div><video><source src="' + _val + '" type="video/mp4"/></video></div>'; break;
                        default: _retVal += '<a target="_blank" href="' + _val + '">' + _val.replace(/^.*\//, '') + '</a>'; break; 
                    }
                }

                _retVal += '</div>';
            }

            return _retVal;
        }
        else if(cConfig.type == 'select')  {
            let _retVal = '';

            if(cVal != null) {
                let _selValues = [cVal];

                if(cConfig.multi) {
                    _selValues = JSON.parse(cVal);
                }

                for(let i = 0; i < _selValues.length; i++) {
                    let _selVal = _selValues[i];

                    if(_retVal != '') _retVal += ', ';

                    if(cConfig.options != null) {
                        var _isIn = false;

                        for(let j = 0; j < cConfig.options.length; j++) {
                            if(cConfig.options[j].value == _selVal) {
                                _isIn = true;
                                _retVal += cConfig.options[j].text;
                                break;
                            }
                        }

                        if(!_isIn) _retVal += _selVal;
                    } 
                }
            }

            return _retVal;
        }
        else {
            return this.byType(cVal, cConfig.type);
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class LocalizationService {

    public statusChange = new Subject<string>();

    currentLanguage:string = null;

    languageLabels = { 
        'en_EN': 'English',
        'it_IT': 'Italiano',
        'de_DE': 'Deutsch',
        'es_ES': 'Español',
        'fr_FR': 'Français',
        'pt_PT': 'Português',
        'ru_RU': 'Русский',
        'zh_ZH': '國語',
        'hr_HR': 'Hrvatski',
        'sr_SR': 'Српски',
        'ba_BA': 'Bosanski',
        'sl_SL': 'Slovenščina',
        'el_EL': 'Ελληνικά',
        'sq_AL': 'Shqip'
    }

    availableApplicationLanguages:Array<string> = [ 'en_EN', 'it_IT' ];
    defaultContentLanguages:Array<string> = [ 'en_EN' ]

    data:LocalizationData = null;
    fallbackData:LocalizationData = null;

    format:LocalizationFormat = null;

    icons:any = null;

    constructor(private http:HttpClient, private environmentService:EnvironmentService, private persistenceService:PersistenceService) {
        this.format = new LocalizationFormat(this);
    }

    init():Promise<void> {
        return new Promise((resolve, reject) => {
            if(this.environmentService.environment.DefaultContentLanguages?.length) this.defaultContentLanguages = this.environmentService.environment.DefaultContentLanguages;
            if(this.environmentService.environment.AvailableApplicationLanguages?.length) this.availableApplicationLanguages = this.environmentService.environment.AvailableApplicationLanguages;

            this.getLanguageData(this.availableApplicationLanguages[0]).then((data) => {
                this.fallbackData = data;

                // TOFIX: qua setta sempre la lingua anche se è la stessa salvata nei dati utente, ha senso?
                let _setLanguage = this.persistenceService.getItem('POIGEST_Language');

                if(this.environmentService.externalParameters.language != null) _setLanguage = this.environmentService.externalParameters.language;

                if(_setLanguage != null) {
                    this.setLanguage(_setLanguage).then(() => {
                        resolve();
                    });
                }
                else {
                    this.setLanguage(navigator.language).then(() => {
                        resolve();
                    });
                }
            })
        })
    }

    private _languageDataMerge(destination:any, source:any) {
        if(source != null) {
            for(let i in source) {
                if(source[i] != null) {
                    if(destination[i] == null || typeof destination[i] != 'object') destination[i] = JSON.parse(JSON.stringify(source[i]))
                    else {
                        if(typeof source[i] == 'object') {
                            this._languageDataMerge(destination[i], source[i])
                        }
                        else destination[i] = source[i];
                    }
                }
            }
        }
    }

    getLanguageData(langId:string):Promise<any> {
        return new Promise((resolve, reject) => {
            let _retVal:any = {};

            this.http.get('assets/icons/fontawesome.json').pipe((catchError(() => {
                return of(null)
            }))).subscribe((data) => {
                this.icons = data;

                this.http.get('assets/localization/' + langId + '.json', {
                    headers: { 'X-Interceptor-Silent': '*' }
                }).pipe((catchError(() => {
                    return of(null)
                }))).subscribe((data) => {
                    this._languageDataMerge(_retVal, data);
    
                    this.http.get('assets/localization-app/' + langId + '.json', {
                        headers: { 'X-Interceptor-Silent': '*' }
                    })
                    .pipe((catchError(() => {
                        return of(null)
                    }))).subscribe((data) => {
                        this._languageDataMerge(_retVal, data);
    
                        this._checkLoadContentData().then(() => {
                            if(this.contentDataByTid != null) {
                                let _translationsObject:any = {};
    
                                for(let i in this.contentDataByTid) {
                                    this._applyContentData(i, langId, _translationsObject)
                                }
    
                                this._languageDataMerge(_retVal, { translations: _translationsObject });
                            }
    
                            resolve(_retVal)
                        })
                    })
                })
            })
        })
    }

    contentDataByTid: { [tid:string]: any } = null;

    setContentData(item:any) {
        if(item.tid != null) {
            this.contentDataByTid[item.tid] = item;
            this._applyContentData(item.tid, this.currentLanguage, this.data.translations)
        }
    }

    private _applyContentData(tid:string, langId:string, translationsObject:any) {
        let _cElement = this.contentDataByTid[tid];
                                
        if(_cElement[langId] != null) {
            
            let _nameSplit = tid.split('.');

            let _cObj = translationsObject;

            for(let i = 0; i < _nameSplit.length - 1; i++) {
                if(_cObj[_nameSplit[i]] == null) _cObj[_nameSplit[i]] = {}
                _cObj = _cObj[_nameSplit[i]]
            }

            _cObj[_nameSplit[_nameSplit.length - 1]] = _cElement[langId];
        }   
    }

    private _checkLoadContentData() {
        return new Promise<void>((resolve) => {
            if(this.contentDataByTid != null) resolve();
            else {
                this.http.get(this.environmentService.environment.APIUrl + '/public/content')
                .pipe((catchError(() => {
                    return of([])
                }))).subscribe((data:Array<any>) => {
                    this.contentDataByTid = {}

                    for(let _item of data) {
                        if(_item.tid != null) {
                            this.contentDataByTid[_item.tid] = _item;
                        }
                    }
                    
                    resolve();
                })
            }
        })
    }

    normalizeLanguage(langId:string) {
        if(langId != null) { // normalizzo langId in modo che accetti anche stringhe da 2 lettere o separate da -
            langId = langId.replace('-', '_')
            if(!/_/.test(langId)) langId = langId.substring(0, 2).toLowerCase() + '_' + langId.substring(0, 2).toUpperCase()
        }

        return langId;
    }

    setLanguage(langId:string):Promise<any> {
        langId = this.normalizeLanguage(langId);

        if(this.availableApplicationLanguages.indexOf(langId) == -1) { // se la lingua specifica non c'è prendo la prima che ha la prima parte uguale, in modo che per esempio en_GB selezioni en_EN
            for(let _language of this.availableApplicationLanguages) {
                if(langId.substring(0, 2) == _language.substring(0, 2)) {
                    langId = _language;
                    break;
                }
            }
        }

        if(this.availableApplicationLanguages.indexOf(langId) == -1) {
            return this.setLanguage(this.availableApplicationLanguages[0]);
        }
        else {
            return new Promise((resolve, reject) => {
                this.getLanguageData(langId).then((data) => {

                    this.data = JSON.parse(JSON.stringify(this.fallbackData))
                    this._languageDataMerge(this.data, data);

                    this.currentLanguage = langId;

                    this.persistenceService.setItem('POIGEST_Language', this.currentLanguage, 'technical');

                    resolve(this.currentLanguage);

                    this.statusChange.next(this.currentLanguage)
                })
            })
        }
    }

    private _addedTranslationAliases:{ [id:string] : string } = {}

    addTranslationAlias(id:string, aliasOf:string) {
        this._addedTranslationAliases[id] = aliasOf;
    }

    private _addedTranslationLinks:{ [id:string] : string } = {}

    addTranslationLink(id:string, aliasOf:string) {
        this._addedTranslationLinks[id] = aliasOf;
    }

    private _addedTranslations:{ [language:string] : { [id:string]: string } } = {
        '*': {}  // NB: language "*" = fallback 
    };

    addTranslation(language:string, id:string, translation:string) {
        if(this._addedTranslations[language] == null) this._addedTranslations[language] = {};
        this._addedTranslations[language][id] = translation;
    }

    private _getTranslationOrObject(id:string):string|{ [id:string]:string } {
        if(this.data == null) return null;
        else {
            if(this._addedTranslationAliases[id] != null) id = this._addedTranslationAliases[id];
            else {
                for(let i in this._addedTranslationLinks) {
                    if(id.startsWith(i)) {
                        id = id.replace(i, this._addedTranslationLinks[i])
                    }
                }
            }
    
            let _retVal = null;
    
            if(this._addedTranslations[this.currentLanguage] != null && this._addedTranslations[this.currentLanguage][id] != null) {
                _retVal = this._addedTranslations[this.currentLanguage][id]
            }
            else {
                let _idSplit = id.split('.');
                _retVal = this.data.translations;
        
                for(let i = 0; i < _idSplit.length; i++) {
                    if(_retVal != null) _retVal = _retVal[_idSplit[i]];
                }
            }

            let _resourcesRegExp = /^RESOURCES\.[^\*&^\.]*\./;
            
            if(_retVal == null && _resourcesRegExp.test(id)) {
                _retVal = this._getTranslationOrObject(id.replace(_resourcesRegExp, 'RESOURCES.*.'));
            }
    
            if(_retVal == null && this._addedTranslations['*'][id] != null) {
                _retVal = this._addedTranslations['*'][id]
            }
    
            return _retVal;
        }
    }

    getTranslationObject(id:string):{ [id:string]:string } {
        let _obj = this._getTranslationOrObject(id);
        if(typeof _obj != 'string') return _obj;
    }

    translate(id:string, replaceData?:any, forceReplace?:boolean):string {
        if(id != null) {
            if(/^\(/.test(id)) {
                let _cFn = eval(id);
                return _cFn(this, replaceData)
            }
            else {
                let _translation = this._getTranslationOrObject(id);

                if(typeof _translation != 'string' && forceReplace) _translation = id;

                if(typeof _translation == 'string') {
                    if(replaceData != null) {
                        let _replaceData:any = {}

                        for(let i in replaceData) {
                            _replaceData[i] = replaceData[i];

                            if(typeof _replaceData[i] == 'string') {
                                let _splitList = _replaceData[i].split('|')
    
                                if(_splitList.length > 1) {
                                    let _val = _splitList[0];
    
                                    for(let j = 1; j < _splitList.length; j++) {
                                        if(this.format[_splitList[j]] != null) {
                                            _val = this.format[_splitList[j]](_val)
                                        }
                                    }
    
                                    _replaceData[i] = _val;
                                }
                            }
                        }

                        _translation = Mustache.render(_translation, _replaceData);
                    }

                    return _translation
                }
                else return id + (replaceData ? ' ' + JSON.stringify(replaceData) : '');
            }
        }
    }

    getCleanResourceId(resource:string) {
        if(resource != null) {
            return resource.replace(/~.*$/, '');
        }
    }

    translateResource(resource:string) {
        return this.translate('RESOURCES.' + this.getCleanResourceId(resource) + '.name')
    }

    translateResourceField(resource:string, field:string) {
        return this.translate('RESOURCES.' + this.getCleanResourceId(resource) + '.fields.' + field + '.label')
    }

    private _remoteTranslateCache:{
        [from:string]: {
            [to:string]: Array<{
                original:string,
                translated:string
            }>
        }
    } = {}

    remoteTranslate(text:string, target:string, source?:string) {
        return this.remoteTranslateAll([text], target, source).pipe(map((data) => {
            return data[0]
        }))
    }

    remoteTranslateAll(textList:Array<string>, target:string, source?:string) {
        return new Observable<string|Array<string>>((observer) => {

            target = target.split('_')[0];
            
            if(source != null) {
                source = source.split('_')[0];
            }          
            
            let _retVal:Array<string> = [];
            let _toSendIndexList:Array<number> = [];

            for(let i = 0; i < textList.length; i++) {
                let _inCache = false;

                if(this._remoteTranslateCache[source] != null && this._remoteTranslateCache[source][target] != null) {
                    for(let _translation of this._remoteTranslateCache[source][target]) {
                        if(_translation.original == textList[i]) {    
                            _retVal[i] = _translation.translated
                            _inCache = true;
                            break;
                        }
                    }
                }

                if(!_inCache) {
                    _toSendIndexList.push(i)
                }
            }

            if(_toSendIndexList.length == 0) {
                setTimeout(() => {
                    observer.next(_retVal)
                    observer.unsubscribe()
                }, 100)
            }
            else {
                let _queryList:Array<string> = [];

                for(let _index of _toSendIndexList) {
                    _queryList.push(textList[_index])
                }

                this.http.post('https://translation.googleapis.com/language/translate/v2?key=' + this.environmentService.environment.GoogleMapsAPIKey, {
                    q: _queryList,
                    source: source,
                    target: target
                }).subscribe((data:any) => {
                    if(this._remoteTranslateCache[source] == null) this._remoteTranslateCache[source] = {}
                    if(this._remoteTranslateCache[source][target] == null) this._remoteTranslateCache[source][target] = []

                    for(let i = 0; i < _toSendIndexList.length; i++) {
                        let _index = _toSendIndexList[i]

                        _retVal[_index] = data.data.translations[i].translatedText
                        
                        this._remoteTranslateCache[source][target].push({
                            original: textList[_index],
                            translated: _retVal[_index]
                        })
                    }

                    observer.next(_retVal)
                    observer.unsubscribe()
                }, (error) => {
                    observer.error(error)
                    observer.unsubscribe()
                })
            }
        })
    }

    private _isTranslationObject(value:any) {
        if(value != null && typeof value == 'object') {
            for(let i in value) {
                if(!/^[a-z][a-z]_[A-Z][A-Z]$/.test(i)) return false;
            }

            return true;
        }
        else return false;
    }

    getTranslatedValue(value:string|{ [lang:string]: string }, preserveNull?:boolean) {
        if(value == null) {
            return preserveNull ? null : '';
        }
        else {
            if(typeof value == 'string') {
                try {
                    let _parsed = JSON.parse(value);
                    if(this._isTranslationObject(_parsed)) value = _parsed;
                }
                catch(ex) {

                }
            }
            
            if(!this._isTranslationObject(value)) return value;
            else {
                if(value[this.currentLanguage] != null) {
                    return value[this.currentLanguage];
                }
                else {
                    return value['en_EN']
                }
            }
        }
    }

    private _addedIconAliases:{ [id:string] : string } = {}

    addIconAlias(id:string, aliasOf:string) {
        this._addedIconAliases[id] = aliasOf;
    }

    private _addedIconLinks:{ [id:string] : string } = {}

    addIconLink(id:string, aliasOf:string) {
        this._addedIconLinks[id] = aliasOf;
    }

    private _getIconOrObject(id:string):string|{ [id:string]:string } {
        if(this.icons == null) return null;
        else {
            for(let i in this._addedIconAliases) {
                if(id.startsWith(i)) {
                    id = id.replace(i, this._addedIconAliases[i])
                }
            }

            if(this._addedIconAliases[id] != null) id = this._addedIconAliases[id];
            else {
                for(let i in this._addedIconLinks) {
                    if(id.startsWith(i)) {
                        id = id.replace(i, this._addedIconLinks[i])
                    }
                }
            }

            let _retVal = null;

            let _idSplit = id.split('.');
            _retVal = this.icons;
    
            for(let i = 0; i < _idSplit.length; i++) {
                if(_retVal != null) _retVal = _retVal[_idSplit[i]];
            }
    
            return _retVal;
        }
    }

    icon(id:string, variant?:string) {
        if(variant == null) variant = 'fa'

        let _icon = this._getIconOrObject(id);

        if(_icon == null) return null;
        else if(typeof _icon == 'string') return variant + ' fa-' + _icon;
        else if(typeof _icon.pro == 'string') return variant + ' fa-' + _icon.pro;
        else return null;
    }
}

@Injectable({
    providedIn: 'root',
})
export class LocalizationDateParserFormatter extends NgbDateParserFormatter {
    constructor(private localizationService:LocalizationService) {
        super();
    }

    parse(value: string): NgbDateStruct {
        if(value != null) {
            let _dateFormat = this.localizationService.data.dateFormats.short;
            if(_dateFormat == null) _dateFormat = 'DD/MM/YYYY';

            let _cDate = moment(value, _dateFormat);

            if(_cDate.isValid()) {
                return {
                    day: _cDate.date(),
                    month: _cDate.month() + 1,
                    year: _cDate.year()
                };
            }
        }

        return null;
    }

    format(date: NgbDateStruct): string {
        if(date != null) {
            let _dateFormat = this.localizationService.data.dateFormats.short;
            if(_dateFormat == null) _dateFormat = 'DD/MM/YYYY';

            let _cDate = moment(new Date(date.year, date.month - 1, date.day));

            if(_cDate.isValid()) {
                return _cDate.format(_dateFormat);
            }
        }

        return '';
    }
}

@Injectable({
    providedIn: 'root',
})
export class LocalizationDateAdapter extends NgbDateAdapter<string> {
    fromModel(value: string): NgbDateStruct {
        if(value != null) {
            let _cDate = moment(value, 'YYYY-MM-DD');

            if(_cDate.isValid()) {
                return {
                    day: _cDate.date(),
                    month: _cDate.month() + 1,
                    year: _cDate.year()
                };
            }
        }

        return null;
    }

    toModel(date: NgbDateStruct): string {
        if(date != null) {
            let _cDate = moment(new Date(date.year, date.month - 1, date.day));

            if(_cDate.isValid()) {
                return _cDate.format('YYYY-MM-DD');
            }
        }
    };
}

@Injectable({
    providedIn: 'root',
})
export class LocalizationatepickerI18n extends NgbDatepickerI18n {
    constructor(private localizationService:LocalizationService) {
        super();
    }

    getDayAriaLabel(date:NgbDateStruct) { return this.localizationService.format.date(new Date(date.year, date.month - 1, date.day), true); }
    getDayNumerals(date:NgbDateStruct) { return date.day.toString(); }
    getMonthFullName(month:number) { return this.localizationService.data.monthNames[month - 1]; }
    getMonthShortName(month:number) { return this.localizationService.data.monthNames[month - 1].substr(0, 3); }
    getWeekdayLabel(weekNumber:number) { return this.localizationService.data.dayNames[weekNumber - 1]; }
    getWeekNumerals(weekNumber:number) { return weekNumber.toString(); }
    getWeekdayShortName(weekDay:number) { return this.localizationService.data.dayNames[weekDay % 7].substr(0, 3); }
    getYearNumerals(year:number) { return year.toString(); }
}