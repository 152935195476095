import { Directive, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PgFormLayout } from '../../models/form.model';
import { DataService } from '../../services/data.service';
import { LocalizationService } from '../../services/localization.service';
import { NotificationsService } from '../../services/notifications.service';
import { AuthService } from '../../services/auth.service';
import { SaveStatusRequest, SaveStatusService } from '../../pg-ui-elements/save-status.service';
import { EnvironmentService } from '../../services/environment.service';
import { OptionMapsService } from '../../services/option-maps.service';
import { PgFormComponent } from '../../pg-form/pg-form/pg-form.component';

@Directive()
export abstract class SingleFormComponent implements OnChanges, OnDestroy { // TODO: ereditare da SingleFormMain, portare gestione traduzioni in classe padre

    @Input() elementId:string = null;
    @Input() returnUrl:string = null;
    @Input() returnFragment:string = null;

    @Input() viewOnly:boolean;

    @Output() valuesChange = new EventEmitter<any>();

    constructor(protected dataService:DataService, protected authService:AuthService, protected localizationService:LocalizationService, protected router:Router, protected route:ActivatedRoute, protected notificationsService:NotificationsService, protected saveStatusService:SaveStatusService, protected optionMapsService:OptionMapsService, protected environmentService:EnvironmentService) { }

    abstract resourceId:string;

    abstract formLayout:PgFormLayout;

    protected async afterInitializeForm() {}
    protected async afterLoadData(values:any) { return values; }
    protected beforeValueChanges(values:any, changes:any) { return values; }

    protected adaptLoadData(values:any) { return values; }
    protected adaptSaveData(values:any) { return values; }

    protected beforeSaveData(values:any) { return values; }

    selectOptions:{ [select:string] : Array<{ value:string, text:string }> } = {}

    private _hasTranslations = false;

    private async _checkInitializeForm() {
        if(!this._isFormInitialized) {
            this._isFormInitialized = true;

            this.selectOptions = this.optionMapsService.getResourceOptionMaps(this.resourceId);

            for(let _field of this.formLayout.fieldList) {
                if(/@.*Translation$/.test(_field.name)) {
                    this._hasTranslations = true;
                }

                let _cleanFieldName = _field.name.replace(/@.*$/, '')

                if(_field.label == 'auto') _field.label = 'RESOURCES.' + this.resourceId + '.fields.' + _cleanFieldName + '.label';
                if(_field.tooltip == 'auto') _field.tooltip = 'RESOURCES.' + this.resourceId + '.fields.' + _cleanFieldName + '.tooltip';
                if(_field.placeholder == 'auto') _field.placeholder = 'RESOURCES.' + this.resourceId + '.fields.' + _cleanFieldName + '.placeholder';

                if(_field.type == 'select') {
                    _field.options = this.selectOptions[_field.name]
                }
                else if(_field.type == 'html' || (_field.type == 'file' && _field.multi) || _field.type == 'location') {
                    this.formLayout.getFieldLayout(_field.name).display = { fullWidth: true }
                }

                if(_field.name == 'realm_id') {
                    _field.default = this.authService.user.realm?.id;
                    _field.visible = this.authService.user.availableRealms.length > 1;
                }
                else if(_field.name == 'language') {
                    if(_field.multi) {
                        _field.default = JSON.stringify(this.localizationService.defaultContentLanguages);
                    }
                    else {
                        _field.default = this.localizationService.defaultContentLanguages[0]
                    }
                }
            }

            await this.afterInitializeForm()

            if(this.environmentService.environment.CustomFormConfig != null) {
                for(let _id of ['*', this.resourceId]) {
                    let _config = this.environmentService.environment.CustomFormConfig[_id];

                    if(_config != null) {
                        for(let i in _config) {
                            let _field = this.formLayout.getFieldByName(i);
    
                            if(_field != null) {
                                for(let k in _config[i]) {
                                    _field[k] = _config[i][k];
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    private _isFormInitialized = false;

    isLoading = false;

    openMode:'create'|'edit' = null;

    private _translationByLanguage:{
        [language:string]: any
    } = null;

    private _getTranslationsIdField() {
        return this.resourceId.replace(/([A-Z])/g, '_$1').replace(/^_/g, '').toLowerCase() + '_id'
    }

    ngOnChanges(): void {
        this.isLoading = true;

        this._translationByLanguage = {};

        let _translationIdField = this._getTranslationsIdField();

        for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
            let _cLanguageBaseObj:any = {
                language: _cLanguage
            }

            _cLanguageBaseObj[_translationIdField] = this.elementId;

            this._translationByLanguage[_cLanguage] = _cLanguageBaseObj;
        }

        this.openMode = this.dataService.isDraftId(this.elementId) ? 'create' : 'edit';

        this._checkRemoveSaveRequest();

        this._checkInitializeForm().then(() => {
            this.formLayout.resetData();

            this.loadData().then((data) => {
                this.isLoading = false;

                this.formLayout.setData(data)
    
                this._checkCreateSaveRequest()
            })
        })
    }

    protected _saveRequest:SaveStatusRequest = null;

    protected _checkCreateSaveRequest() {
        if(!this.viewOnly) {
            // TODO: nella create dovrebbe andarci anche la reset per cancellare il draft

            let _resourceTranslation = this.resourceId.replace(/([A-Z])/g, '-$1').toLowerCase()

            this._saveRequest = this.saveStatusService.createRequest(this.openMode, 'forms-' + _resourceTranslation + '.' + _resourceTranslation + '-' + this.openMode, (action) => {
                if(action == 'cancel') {
                    this.cancelEdit()
                }
                else if(action == 'save') {
                    this.saveData()
                }
            }, null, { save: true, cancel: true })
        }
    }

    protected _checkRemoveSaveRequest() {
        if(this._saveRequest != null) this.saveStatusService.removeRequest(this._saveRequest)
    }

    ngOnDestroy() {
        this._checkRemoveSaveRequest();
    }

    protected loadData() {
        return new Promise<any>((resolve, reject) => {
            if(this.dataService.isDraftId(this.elementId)) {
                this.dataService.getDraftElementData(this.resourceId, this.elementId).subscribe((data) => {
                    data = this.adaptLoadData(data);

                    this.afterLoadData(data).then((data) => {
                        resolve(data)
                    })
                })
            }
            else {
                this.dataService.getElementData(this.resourceId, this.elementId).subscribe((data) => {
                    this._checkLoadTranslations(data).then(() => {
                        data = this.adaptLoadData(data);

                        this.afterLoadData(data).then((data) => {
                            resolve(data)
                        })
                    })
                })
            }
        })
    }

    private _checkLoadTranslations(data:any) {
        return new Promise<any>((resolve, reject) => {
            if(!this._hasTranslations) {
                resolve(data)
            }
            else {
                this.dataService.getResourceData(this.resourceId + 'Translation', null, this.resourceId, this.elementId).subscribe((translData) => {
                    if(translData != null) {
                        for(let _cTransl of translData) {
                            this._translationByLanguage[_cTransl.language] = _cTransl;
                
                            for(let i in _cTransl) {
                                if(i != 'id' && i != 'created_at' && i != 'updated_at' && i != 'deleted_at' && i != 'language') {
                                    let _localeValue:any = {};
                
                                    for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                                        if(this._translationByLanguage[_cLanguage]) {
                                            _localeValue[_cLanguage] = this._translationByLanguage[_cLanguage][i];
                                        }
                                        else {
                                            _localeValue[_cLanguage] = {}
                                        }
                                    }
                
                                    data[i + '@' + this.resourceId + 'Translation'] = JSON.stringify(_localeValue);
                                }
                            }
                        }
                    }
        
                    resolve(data)
                })
            }
        })
    }

    onValueChange(changes:any) {
        let _values = this.formLayout.getData();
        _values = this.beforeValueChanges(_values, changes);
        _values = this.adaptSaveData(_values);

        if(this.dataService.isDraftId(this.elementId)) {
            this.dataService.saveDraftElementData(this.resourceId, this.elementId, _values).subscribe(() => {})
        }

        this.valuesChange.emit(_values)
    }

    private _updateTranslations(values:any) {
        let _keysToRemove = [];

        for(let i in values) {
            let _cResource = i.split('@')[1];

            if(_cResource != null) {
                _keysToRemove.push(i);

                if(values[i] != null) {
                    let _localeValue = JSON.parse(values[i]);

                    for(let _cLanguage in _localeValue) {
                        if(this._translationByLanguage[_cLanguage] != null) {
                            this._translationByLanguage[_cLanguage][i.split('@')[0]] = _localeValue[_cLanguage]
                        }
                    }

                    for(let _cLanguage of this.localizationService.availableApplicationLanguages) {
                        // prendo la prima lingua che ha un valore e la uso come valore del campo di base
                        if(_localeValue[_cLanguage] != null && _localeValue[_cLanguage] != '') {
                            values[i.split('@')[0]] = _localeValue[_cLanguage];
                            break;
                        }
                    }
                }
                else {
                    values[i.split('@')[0]] = null;
                }
            }
        }

        for(let _cKey of _keysToRemove) {
            delete values[_cKey];
        }

        return values
    }

    isSaving = false;

    @ViewChild('formComponent') formComponent:PgFormComponent;

    saveData() {
        if(!this.isSaving && this.formComponent.validateForm()) {
        
            this.isSaving = true;

            let _values = this.formLayout.getData();
            _values = this.adaptSaveData(_values);

            _values = this._updateTranslations(_values);
            _values = this.beforeSaveData(_values)
            
            this.dataService.saveElementData(this.resourceId, this.elementId, _values).subscribe((data) => {
                if(data == null) {
                    this.isSaving = false;
                }
                else {
                    this._checkSaveTranslations(data).then(() => {
                        this.isSaving = false;

                        let _action:'update'|'insert' = 'update';
                        if(this.openMode == 'create') _action = 'insert'

                        this.notificationsService.addLocalNotification('forms-result-splash.action-text-' + _action, 'success', null, null, 3000)

                        this.router.navigate([this.returnUrl], { relativeTo: this.route })    
                    })
                }
            })
        }
    }

    private _checkSaveTranslations(data:any) {
        return new Promise<any>((resolve, reject) => {
            if(!this._hasTranslations) {
                resolve(data)
            }
            else {
                let _translationIdField = this._getTranslationsIdField();

                this.dataService.saveElementTranslations(data.id, this.resourceId + 'Translation', _translationIdField, data.languages, this._translationByLanguage).subscribe((data) => {
                    resolve(data)
                })
            }
        })
    }

    cancelEdit() {
        if(!this.isSaving) {
            this.router.navigate([this.returnUrl], { relativeTo: this.route, fragment: this.returnFragment })
        }
    }
}
