<div class="PGResourcePicker">
    <app-pg-loading *ngIf="isLoading"></app-pg-loading>
    <ng-container *ngIf="!isLoading">
        <span *ngIf="customContent" (click)="openModal()">
            <ng-content></ng-content>
        </span> 
        <ng-container *ngIf="!customContent">
            <div class="PGResourcePicker-Selection">
                <ng-container *ngFor="let selection of selectionData">
                    <ng-container *ngIf="selection != null">
                        <app-pg-file-preview *ngIf="viewType == 'Files'" [fileData]="selection" [viewMode]="fileType"></app-pg-file-preview>
                        <app-pg-semantic-view-simplecard *ngIf="viewType != 'Files'" [resourceId]="resourceId" [elementData]="selection"></app-pg-semantic-view-simplecard>
                    </ng-container>
                </ng-container>
            </div>
            <button *ngIf="selectionData.length == 0" class="btn btn-primary PGResourcePicker-Add" (click)="openModal()">
                <i class="fa-regular fa-chevron-right"></i><span>{{ 'pg-resource-picker.select-resource' | locTranslate:{ resource: resourceName} }}</span>
            </button>
            <button *ngIf="selectionData.length > 0" class="btn btn-sm btn-primary PGResourcePicker-Edit" (click)="openModal()">
                <i class="fa-regular fa-edit"></i>
            </button>
        </ng-container>
    </ng-container>
</div>

<ng-template #modalPicker>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'pg-resource-picker.select-resource' | locTranslate:{ resource: resourceName} }}</h4>
        <button type="button" class="modal-close btn btn-link" (click)="dismissModal()">
            <i class="fa-regular fa-times"></i>
        </button>
    </div>
    <div class="PGResourcePicker-Modal-Body modal-body p-0 bg-light">
        <div class="py-3 px-2 mx-1">
            <app-pg-filter-editor-search [status]="filterStatus" [appliedFilter]="appliedFilter" [hasTags]="hasTags" [hasSystemTags]="hasSystemTags" (applySearch)="doApplySearch()"></app-pg-filter-editor-search>
        </div>

        <div>
            <app-pg-resource-embed [resourceId]="resourceId" [relatedResource]="relatedResource" [relatedElement]="relatedElement" [filterStatus]="appliedFilter" [selectionMode]="selectionMode || 'single'" [selectionField]="selectionField" [(selection)]="modalSelection"></app-pg-resource-embed>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="dismissModal()">
            {{ 'pg-resource-picker.modal-cancel' | locTranslate }}
        </button>
        <button class="btn btn-primary" (click)="closeModal()">
            {{ 'pg-resource-picker.modal-ok' | locTranslate }}
        </button>
    </div>
</ng-template>
